const tr = {
  admin: {
    titles: {
      dashboard: 'Painel',
      profile: 'Perfil',
      logout: 'Sair',
      investors: 'Investidores',
      issuers: 'Emissores',
      legalInvestors: 'Investidores PJ',
      proposalList: 'Propostas de tokenização',
      tokenProposal: 'Proposta de tokenização',
      investorGroups: 'Grupos de investimento',
    },
    menuLabel: {
      dashboard: 'Painel',
      investors: {
        title: 'Investidores',
        physicalInvestors: 'Investidores PF',
        legalInvestors: 'Investidores PJ',

      },
      proposalList: 'Propostas de tokenização',
      issuers: 'Emissores',
      investorGroups: 'Grupos de investimento',
    },
    types: {
      userTypes: {
        IN_ANALYSIS: 'Em análise',
        ACTIVE: 'Ativo',
      },
      investorTypes: {
        legalperson: 'Pessoa Jurídica',
        physicalperson: 'Pessoa física',

      },
      issuerTypes: {
        IN_ANALYSIS: 'Em análise',
        APPROVED: 'Aprovado',
        INCOMPLETE: 'Incompleto',
        ACTIVE: 'Ativo',
      },
      tokenProposalStatus: {
        inAnalysis: 'Em análise',
        negotiating: 'Negociação',
        approved: 'Aprovada',
        rejected: 'Rejeitada',
        preApproved: 'Pré-aprovada',
        annulled: 'Anulada',
      },
    },
    pages: {
      login: {
        title: 'Olá, admin!',
        body: 'Por favor, digite o seu e-mail e sua senha.',
        email: 'E-mail',
        password: 'Senha',
        submit: 'Entrar',
        alter: 'Alterar senha',
        noAccount: 'Não possui uma conta?',
        register: 'Cadastrar',
        loading: 'Carregando...',
        validationErr: {
          email: 'Por favor, insira seu email',
          password: 'Por favor, insira sua senha',
          recaptcha: 'Recaptcha inválido',
        },
        apiErr: {
          title: 'Falha ao fazer login',
          login: 'Por favor verifique suas credenciais',
        },
      },
      investors: {
        name: 'Nome',
        email: 'E-mail',
        type: 'Tipo',
        status: 'Status',
        title: 'Investidores',
        cnpj: 'CNPJ',
        corporateName: 'Razão Social',
        action: 'Ações',
        details: 'Detalhes',
        createdAt: 'Criado em',
        monthlyIncome: 'Renda mensal',
        cpf: 'CPF',
        approve: 'Aprovar',
        reject: 'Rejeitar',
        documentation: 'Documentação',
        selfieWithDocument: 'Foto com o documento',
        documentPhoto: 'Foto do documento',
        socialContract: 'Contrato social',
        user: 'Usuário',
        approveSwal: {
          success: "Investidor aprovado",
          sucessMessage: "O investidor foi aprovado com sucesso, agora ele já pode acessar os investimentos e realizar depósitos",
        },
        apiErr: {
          title: 'Falha ao carregar investidores',
          body: 'Por favor verifique sua conexão',
        },
        approveError: {
          title: 'Falha ao aprovar investidor',
          body: 'Por favor verifique sua conexão',
        }
      },
      issuers: {
        name: 'Nome',
        email: 'E-mail',
        type: 'Tipo',
        status: 'Status',
        title: 'Emissor',
        cnpj: 'CNPJ',
        corporateName: 'Razão social',
        action: 'Ações',
        details: 'Detalhes',
        createdAt: 'Criado em',
        approve: 'Aprovar',
        reject: 'Rejeitar',
        documentation: 'Documentação',
        socialContract: 'Contrato social',
        user: 'Usuário',
        detailedDescription: 'Descrição detalhada',
        foundationDate: 'Data de fundação',
        simpleDescription: 'Descrição simples',
        shareCapital: 'Capital Social',
        approveSwal: {
          success: "Emissor aprovado",
          sucessMessage: "O emissor foi aprovado com sucesso, agora ele já pode criar os investimentos e realizar ofertas",
        },
        apiErr: {
          title: 'Falha ao carregar emissores',
          body: 'Por favor verifique sua conexão',
        },
        approveError: {
          title: 'Falha ao aprovar emissor',
          body: 'Por favor verifique sua conexão',
        }
      },
      tokenProposal: {
        title: 'Proposta de token',
        image: 'Imagem do token',
        name: 'Nome do token (Máx: 16 caracteres)',
        invoiceValue: 'Valor total da nota fiscal',
        fundingDateLimit: 'Data de recebimento (Saque)',
        liquidationDate: 'Data de pagamento (Depósito)',
        discountPercentage: 'Taxa de desconto',
        valueToReceive: 'Valor a receber',
        description: 'Descrição do token',
        additionalDoc: 'Documento fiscal/Nota fiscal',
        additionalDocDescription: 'Descrição do documento fiscal',
        sendProposal: 'Enviar proposta',
        addDoc: 'Adicionar outro documento',
        preview: 'Pré-visualização',
        remove: 'Remover este documento',
        approve: 'Aprovar',
        counterPropose: 'Contra proposta',
        sendCounterPropose: 'Enviar contra proposta',
        analysis: 'Em análise',
        cancel: 'Cancelar',
        counterProposal:
        {
          altered: 'Alterado na contra proposta',
          apiErr: {
            title: 'Falha ao enviar contra proposta',
            body: 'Por favor verifique sua conexão',
          },
          approveSwal: {
            success: "Contra proposta enviada",
            sucessMessage: "O emissor irá avaliar a contra proposta",
          },
        },
        upload: {
          apiErr: {
            title: 'Falha ao enviar imagem',
            body: 'Por favor verifique sua conexão',
          },

        },
        approveProposal: {
          apiErr: {
            title: 'Falha ao enviar aprovar proposta',
            body: 'Por favor verifique sua conexão',
          },
          approveSwal: {
            success: "Proposta aprovada",
            sucessMessage: "O token gerado já está disponível para os investidores",
          },
        },
      },
      investorGroups: {
        title: 'Grupos de investimentos',
        name: 'Nome',
        description: 'Descrição',
        details: 'Detalhes',
        action: 'Ação',
        investors: 'Investidores',
        tokens: 'Tokens',
        userId: 'ID',
        tokenId: 'ID',
        type: 'Tipo',
        cpfCnpj: 'CPF/CNPJ',
        removeInvestor: 'Remover investidor',
        vinculateInvestor: 'Vincular investidor',
        removeToken: 'Remover token',
        vinculateToken: 'Vincular token',
        vinculateInvestors: 'Vincular investidores',
        vinculateToken: 'Vincular token',
        vinculateTokens: 'Vincular tokens',
        cancel: 'Cancelar',
        apiErr: {
          title: 'Falha ao carregar grupos de investimentos',
          body: 'Por favor verifique sua conexão',
        },
        detailErr: {
          title: 'Falha ao carregar grupo de investimento',
          body: 'Por favor verifique sua conexão',
        },
        investorsErr: {
          title: 'Falha ao carregar os investidores do grupo de investimento',
          body: 'Por favor verifique sua conexão',
        },
        investorsVinculateErr: {
          title: 'Falha ao carregar os investidores para vinculação',
          body: 'Por favor verifique sua conexão',
        },
        vinculateSwal: {
          success: "Investidores vinculados com sucesso",
          sucessMessage: "Os investidores foram adicionados ao grupo com sucesso",
          fail: "Falha ao vincular investidores",
          failMessage: "Tente novamente mais tarde",
        },
        vinculateTokenSwal: {
          success: "Tokens vinculados com sucesso",
          sucessMessage: "Os tokens foram adicionados ao grupo com sucesso",
          fail: "Falha ao vincular tokens",
          failMessage: "Tente novamente mais tarde",
        },
        removeSwal: {
          success: "Investidores removidos com sucesso",
          sucessMessage: "Os investidores foram removidos do grupo com sucesso",
          fail: "Falha ao remover investidores",
          failMessage: "Tente novamente mais tarde",
        },
        removeTokenSwal: {
          success: "Tokens removidos com sucesso",
          sucessMessage: "Os tokens foram removidos do grupo com sucesso",
          fail: "Falha ao remover tokens",
          failMessage: "Tente novamente mais tarde",
        },
      }

    },
    components: {
      footer: {
        title: 'Todos os direitos reservados por ',
        corporate: 'Criptech',
      },
      proposalsList: {
        name: 'Nome',
        invoiceValue: 'Valor da nota fiscal',
        valueToReceive: 'Valor a receber',
        fundingLimitDate: 'Data de captação',
        liquidationDate: 'Data de liquidação',
        actions: 'Ações',
        details: 'Detalhes',

        action: {
          edit: 'Editar proposta',
          view: 'Ver nova proposta',
          detail: 'Detalhes do token',
        },
      },
    },
  },
};

export default tr;
