import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'pages.investors.corporateName'} />,
    Footer: <Translator path={'pages.investors.corporateName'} />,
    accessor: 'legalUser.corporateName',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investors.cnpj'} />,
    Footer: <Translator path={'pages.investors.cnpj'} />,
    accessor: 'legalUser.cnpj',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investors.status'} />,
    Footer: <Translator path={'pages.investors.status'} />,
    accessor: 'statusFormated',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investors.action'} />,
    Footer: <Translator path={'pages.investors.action'} />,
    accessor: 'action',
    isSortable: false,
  },
];
