import React from 'react';
import { Translator } from '@components';

const StatusCard = ({ status }) => {
  return (
    <div
      className="col-12"
      style={{
        borderRadius: '200px',
        backgroundColor: `${status === 'IN_ANALYSIS'
          ? '#FF9900'
          : status === 'NEGOTIATING'
            ? '#2E291E'
            : status === 'REJECTED'
              ? '#E61D2B'
              : status === 'ACCEPTED'
                ? '#009847'
                : status === 'PRE_ACCEPTED'
                  ? '#005829'
                  : status === 'ANNULLED'
                    ? '#A30914'
                    : ''
          }`,
        textAlign: 'center',
      }}
    >
      <p className="statement-status-card" style={{ color: 'var(--white)' }}>
        {status === 'IN_ANALYSIS' ? (
          <Translator path="types.tokenProposalStatus.inAnalysis" />
        ) : status === 'NEGOTIATING' ? (
          <Translator path="types.tokenProposalStatus.negotiating" />
        ) : status === 'REJECTED' ? (
          <Translator path="types.tokenProposalStatus.rejected" />
        ) : status === 'ACCEPTED' ? (
          <Translator path="types.tokenProposalStatus.approved" />
        ) : status === 'PRE_ACCEPTED' ? (
          <Translator path="types.tokenProposalStatus.preApproved" />
        ) : status === 'ANNULLED' ? (
          <Translator path="types.tokenProposalStatus.annulled" />
        ) : (
          ''
        )}
      </p>
    </div>
  );
};

export default StatusCard;
