import { createContext, useCallback, useContext, useState } from 'react';
import * as investorGroupsService from '../../services/Admin/investorGroupsService';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';


export const InvestorGroupsInvestorContext = createContext({});

const InvestorGroupsInvestorProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loadingInvestorGroupInvestors, setLoadingInvestorGroupInvestors] = useState(false);
  const [loadingInvestorsToVinculate, setLoadingInvestorsToVinculate] = useState(false);
  const [vinculatingInvestors, setVinculatingInvestors] = useState(false);
  const [removingInvestors, setRemovingInvestors] = useState(false);
  const [investorsToVinculate, setInvestorsToVinculate] = useState();
  const [pageInvestorsToVinculate, setPageInvestorsToVinculate] = useState();
  const [pageInvestorsList, setPageInvestorsList] = useState();
  const [totalPageInvestorsToVinculate, setTotalPageInvestorsToVinculate] = useState(0);
  const [totalPageInvestorsLists, setTotalPageInvestorsLists] = useState(0);
  const [investorGroupInvestors, setInvestorGroupInvestors] = useState();
  const [selectedUsers, setSelectedUser] = useState([]);
  const [selectedUsersRemove, setSelectedUserRemove] = useState([]);
  const [showModalVinculationInvestor, setShowModalVinculationInvestor] = useState(false);
  const [showModalVinculationToken, setShowModalVinculationToken] = useState(false);


  const getInvestorGroupInvestors = useCallback(async (investorGroupId, page) => {
    setLoadingInvestorGroupInvestors(true);
    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 12,
      };
      const { data } = await investorGroupsService.getInvestorGroupInvestors(investorGroupId, params);
      setTotalPageInvestorsLists(Math.ceil(data.total / params.pageSize));

      setInvestorGroupInvestors(data.data);
    } catch (error) {
      const errTitle = t('pages.investorGroups.investorsErr.title');
      const errBody = t('pages.investorGroups.investorsErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingInvestorGroupInvestors(false);
    }
  }, []);

  const getInvestorsToVinculate = useCallback(async (investorGroupId, page) => {
    setLoadingInvestorsToVinculate(true);
    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 8,
      };
      const { data } = await investorGroupsService.getInvestorsToVinculate(investorGroupId, params);
      setTotalPageInvestorsToVinculate(Math.ceil(data.total / params.pageSize));

      setInvestorsToVinculate(data.data);
    } catch (error) {
      const errTitle = t('pages.investorGroups.investorsVinculateErr.title');
      const errBody = t('pages.investorGroups.investorsVinculateErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingInvestorsToVinculate(false);
    }
  }, []);


  const vinculateInvestors = useCallback(async (investorGroupId, investors) => {
    setVinculatingInvestors(true);
    try {
      const { data } = await investorGroupsService.addInvestorsToInvestorGroup(investorGroupId, investors);
      const successHeader = t('pages.investorGroups.vinculateSwal.success');
      const successMessage = t('pages.investorGroups.vinculateSwal.sucessMessage');
      swal(successHeader, successMessage, 'success');
      getInvestorsToVinculate(investorGroupId);
      getInvestorGroupInvestors(investorGroupId);
      setSelectedUser([])
    } catch (error) {
      const errTitle = t('pages.investorGroups.vinculateSwal.fail');
      const errBody = t('pages.investorGroups.vinculateSwal.failMessage');
      swal(errTitle, errBody, 'error');
    } finally {
      setVinculatingInvestors(false);
    }
  }, []);

  const removeInvestors = useCallback(async (investorGroupId, investors) => {
    setRemovingInvestors(true);
    try {
      const { data } = await investorGroupsService.removeInvestorsFromInvestorGroup(investorGroupId, investors);
      const successHeader = t('pages.investorGroups.removeSwal.success');
      const successMessage = t('pages.investorGroups.removeSwal.sucessMessage');
      swal(successHeader, successMessage, 'success');
      getInvestorGroupInvestors(investorGroupId);
      setSelectedUserRemove([])
    } catch (error) {
      const errTitle = t('pages.investorGroups.removeSwal.fail');
      const errBody = t('pages.investorGroups.removeSwal.failMessage');
      swal(errTitle, errBody, 'error');
    } finally {
      setRemovingInvestors(false);
    }
  }, []);


  return (
    <InvestorGroupsInvestorContext.Provider
      value={{

        setPageInvestorsList,
        pageInvestorsList,
        showModalVinculationInvestor,
        setShowModalVinculationInvestor,
        getInvestorsToVinculate,
        investorsToVinculate,
        setInvestorsToVinculate,
        loadingInvestorsToVinculate,
        setPageInvestorsToVinculate,
        pageInvestorsToVinculate,
        totalPageInvestorsToVinculate,
        getInvestorsToVinculate,
        vinculatingInvestors,
        vinculateInvestors,
        selectedUsers,
        setSelectedUser,
        selectedUsersRemove,
        setSelectedUserRemove,
        removeInvestors,
        removingInvestors,
        setRemovingInvestors,
        loadingInvestorGroupInvestors,
        totalPageInvestorsLists,
        investorGroupInvestors,
        getInvestorGroupInvestors,
        showModalVinculationToken,
        setShowModalVinculationToken,
      }}
    >
      {children}
    </InvestorGroupsInvestorContext.Provider>
  );
};

const useInvestorGroupsInvestors = () => {
  return useContext(InvestorGroupsInvestorContext);
};

export { InvestorGroupsInvestorProvider, useInvestorGroupsInvestors };
