import React, { useContext } from 'react';
import { connect } from 'react-redux';

/// React router dom
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';

/// Css
import '../../css/index.css';
import '../../css/chart.css';
import '../../css/step.css';

/// Layout
import Nav from '../../layouts/nav';
import ScrollToTop from '../../layouts/ScrollToTop';
import { ThemeContext } from '@contexts/ThemeContext';

import { Footer } from '@components';
import { Dashboard, Investors, LegalInvestors, InvestorDetails, Issuers, IssuerDetails, AdminProposalList, ProposalDetails, InvestorGroups, InvestorGroupDetails, } from '@pages-admin';

const Markup = (props) => {
  const allroutes = [
    { url: '/dashboard', Component: Dashboard },

    { url: '/investors', Component: Investors },
    { url: '/legalInvestors', Component: LegalInvestors },
    { url: '/investors/:userId', Component: InvestorDetails },
    { url: '/issuers/:userId', Component: IssuerDetails },
    { url: '/issuers', Component: Issuers },
    { url: '/proposalList', Component: AdminProposalList },
    { url: '/tokenProposal/:proposalId', Component: ProposalDetails },
    { url: '/investorGroups', Component: InvestorGroups },
    { url: '/investorGroups/:investorGroupId', Component: InvestorGroupDetails },



  ];

  const token = localStorage.getItem('token');
  const path = window.location.pathname;
  const noAuthPaths = [
    '/publictokens',
    '/publictokenslite',
    '/register',
    '/register/email',
    '/register/password',
    '/reset',
    '/login',
  ];

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {allroutes.map(({ Component, url }, i) => {
            return <Route key={i} exact path={url} element={<Component />} />;
          })}
          <Route
            path="*"
            element={
              token ? (
                path.includes('/dashboard') || path.includes('/investment') ? (
                  ''
                ) : (
                  <Navigate to="/dashboard" />
                )
              ) : !noAuthPaths.includes(path) ? (
                <Navigate to="/login" />
              ) : (
                ''
              )
            }
          />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
      <div id="main-wrapper" className={`show ${menuToggle ? 'menu-toggle' : ''}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <>
              <Outlet />
            </>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(Markup);
