import 'react-datepicker/dist/react-datepicker.css';

import { InputText, Translator, Loading, PopOver } from '@components';
import { useAdminTokenProposal } from '@contexts-admin';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { noPreview } from '@assets';
import { Buffer } from 'buffer';
import { moneyMask } from '@utils';

const ProposalDetails = () => {

    const [choosenImage, setChoosenImage] = useState('');
    const [preview, setPreview] = useState('');

    const [tokenName, setTokenName] = useState('');
    const [invoiceValue, setInvoiceValue] = useState(0);
    const [valueToReceive, setValueToReceive] = useState(0);
    const [fundingLimitDate, setFundingLimitDate] = useState(undefined);
    const [liquidationDate, setLiquidationDate] = useState(undefined);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [description, setDescription] = useState('');
    const [additionalDocs, setAdditionalDocs] = useState([]);


    const [proposalStatus, setProposalStatus] = useState('');
    const [tokenImage, setTokenImage] = useState('');
    const [inputType, setInputType] = useState('text');

    const [isPreviewProposal, setIsPreviewProposal] = useState(true);
    const [changedFields, setChangedFields] = useState([]);

    const {
        tokenProposal,
        loading,
        loadingImage,
        getAdminTokenProposal,
        downloadTokenImage,
        sendAdminCounterTokenProposal,
        uploadTokenImage,
        imageLink,
        approveTokenProposal,
    } = useAdminTokenProposal();


    let errorsObj = {
        name: '',
        image: '',
        invoiceValue: '',
        valueToReceive: '',
        fundingLimitDate: '',
        liquidationDate: '',
        discountPercentage: '',
        description: '',
        link: '',
        docDescription: '',
        fiscalDoc: '',
        fiscalDocDesc: '',
    };
    const [errors, setErrors] = useState(errorsObj);

    const { t } = useTranslation();
    const { proposalId } = useParams();

    useEffect(() => {
        getAdminTokenProposal(proposalId);
    }, []);

    useEffect(() => {
        if (Object.keys(tokenProposal).length === 0) {
            return;
        }
        resetFormWithData();
    }, [tokenProposal]);

    useEffect(() => {
        const download = async () => {
            const imageBuffer = await downloadTokenImage(tokenImage);
            const newSrc = Buffer.from(imageBuffer, 'binary').toString('base64');
            setPreview(newSrc);
        };

        if (Object.keys(tokenProposal).length !== 0 && tokenImage !== '') {
            download();
        }
        return;
    }, [tokenImage]);

    useEffect(() => {
        if (invoiceValue && discountPercentage) {
            setValueToReceiveWithMask(`${+removeMasks(invoiceValue) - +discountPercentage * +removeMasks(invoiceValue)}`);
        }
    }, [invoiceValue, discountPercentage]);

    useEffect(() => {
        if (!choosenImage) {
            setPreview('');
            return;
        }

        const upload = async () => {
            await uploadTokenImage(choosenImage);
        };

        upload();
        setTokenImage(imageLink);
    }, [choosenImage]);



    const setValueToReceiveWithMask = (value) => {
        if (+removeMasks(invoiceValue) >= 1)
            setValueToReceive(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value));
        else setValueToReceive('R$ 0,00');
    };

    const resetFormWithData = () => {
        setTokenName(tokenProposal.name);
        setInvoiceValue(tokenProposal.invoiceValue?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' }));
        setDescription(tokenProposal.description);
        setProposalStatus(tokenProposal.status);
        setTokenImage(tokenProposal.image);
        setDiscountPercentage(tokenProposal.discountPercentage);
        setValueToReceive(tokenProposal.valueToReceive?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' }));
        const fundingDate = `${tokenProposal.fundingLimitDate} 00:00:00`;
        const liquidateDate = `${tokenProposal.liquidationDate} 00:00:00`;
        setFundingLimitDate(new Date(fundingDate));
        setLiquidationDate(new Date(liquidateDate));

        const previousDocs = tokenProposal?.tokenProposalAdditionalDocs.map((doc) => {
            return { link: doc.link, description: doc.description, id: doc.tokenProposalAdditionalDocId };
        });

        setAdditionalDocs(previousDocs);

        if (tokenProposal.updatedFields) {
            const updatedFields = tokenProposal.updatedFields.split(',');
            setChangedFields(updatedFields);
        }
    };

    const setInvoiceValueWithMask = (value) => {
        setInvoiceValue(moneyMask(value));
    };

    const submitForm = (e) => {
        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (tokenName === '') {
            errorObj.name = t('pages.tokenProposal.errors.noName');
            error = true;
        }

        if (preview === '') {
            errorObj.image = t('pages.tokenProposal.errors.noImage');
            error = true;
        }

        if (invoiceValue === 0) {
            errorObj.invoiceValue = t('pages.tokenProposal.errors.noInvoice');
            error = true;
        }

        if (valueToReceive === 0) {
            errorObj.valueToReceive = t('pages.tokenProposal.errors.noValueToReceive');
            error = true;
        }

        if (fundingLimitDate === undefined) {
            errorObj.fundingLimitDate = t('pages.tokenProposal.errors.noFundingLimitDate');
            error = true;
        }

        if (liquidationDate === undefined) {
            errorObj.liquidationDate = t('pages.tokenProposal.errors.noLiquidationDate');
            error = true;
        }

        if (discountPercentage === 0) {
            errorObj.discountPercentage = t('pages.tokenProposal.errors.noDiscountPercentage');
            error = true;
        }

        if (description === '') {
            errorObj.description = t('pages.tokenProposal.errors.noDescription');
            error = true;
        }

        if (additionalDocs[0].link === '') {
            errorObj.fiscalDoc = t('pages.tokenProposal.errors.noFiscalDoc');
            error = true;
        }

        if (additionalDocs[0].description === '') {
            errorObj.fiscalDocDesc = t('pages.tokenProposal.errors.noFiscalDesc');
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        const reqBody = {
            tokenProposalId: tokenProposal.tokenProposalId,
            name: tokenProposal.tokenName !== tokenName ? tokenName : undefined,
            image: tokenProposal.tokenImage !== tokenImage ? tokenImage : undefined,
            invoiceValue: tokenProposal.invoiceValue !== +removeMasks(invoiceValue) ? +removeMasks(invoiceValue) : undefined,
            discountPercentage: tokenProposal.discountPercentage !== +discountPercentage ? +discountPercentage : undefined,
            valueToReceive: tokenProposal.valueToReceive !== +removeMasks(valueToReceive) ? +removeMasks(valueToReceive) : undefined,
            fundingLimitDate: tokenProposal.fundingLimitDate !== new Date(fundingLimitDate).toISOString().split(/[A-Z]/)[0] ? new Date(fundingLimitDate).toISOString().split(/[A-Z]/)[0] : undefined,
            liquidationDate: tokenProposal.liquidationDate !== new Date(liquidationDate).toISOString().split(/[A-Z]/)[0] ? new Date(liquidationDate).toISOString().split(/[A-Z]/)[0] : undefined,
            description: tokenProposal.description !== description ? description : undefined,
        };

        sendAdminCounterTokenProposal(reqBody);

    };

    const onApprove = () => {
        approveTokenProposal(proposalId)
    };

    const removeMasks = (value) => {
        if (value) {
            const newValue = value
                ?.replace('R', '')
                ?.replace('$', '')
                ?.replaceAll('.', '')
                ?.replaceAll(',', '.')
                ?.replaceAll(' ', '');

            return newValue;
        }
    };

    if (loading) return <Loading />;

    if (tokenProposal) {
        return (
            <div className="page-wrapper">
                <div className="card card-proposal">
                    <div className="card-title card-title-edit me-4">
                        <Translator path={'pages.tokenProposal.title'} />

                        {proposalStatus === 'IN_ANALYSIS' ? (
                            <div className="status-card analysis">
                                <i className="material-icons icon-border">priority_high</i>
                                <span className="m-0">
                                    <Translator path={'types.tokenProposalStatus.inAnalysis'} />
                                </span>
                            </div>
                        ) : proposalStatus === 'NEGOTIATING' ? (
                            <div className="status-card negotiating">
                                <i className="material-icons icon-border">handshake</i>
                                <span className="m-0">
                                    <Translator path={'types.tokenProposalStatus.negotiating'} />
                                </span>
                            </div>
                        ) : (
                            proposalStatus === 'PRE_ACCEPTED' ?
                                <div className="status-card preaccepted">
                                    <i className="material-icons icon-border">check</i>
                                    <span className="m-0">
                                        <Translator path={'types.tokenProposalStatus.preApproved'} />
                                    </span>
                                </div>
                                : ""
                        )}
                    </div>
                    <div className="card-stroke"></div>
                    <div className="card-content">
                        <div className="form col-12">
                            <form onSubmit={submitForm}>

                                <div className="token-media image position-relative">
                                    {loadingImage ? (
                                        <>
                                            <div
                                                className="loading-spinner"
                                                style={{
                                                    width: '5.625rem',
                                                    height: '5.625rem',
                                                    position: 'absolute',
                                                }}
                                            />
                                            <img
                                                src={noPreview}
                                                alt=""
                                                style={{ width: '5.625rem', height: '5.625rem', margin: '0 !important' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={`data:image/jpeg;base64,${preview}`}
                                                alt=""
                                                style={{ width: '5.625rem', height: '5.625rem' }}
                                            />
                                            {
                                                !isPreviewProposal ?
                                                    <div
                                                        className="upload-button"
                                                        title=""
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        data-original-title="update"
                                                        style={{ backgroundColor: '#FF9900' }}
                                                    >

                                                        <input
                                                            type="file"
                                                            className="update-file"
                                                            style={{ backgroundColor: '#FF9900' }}
                                                            onChange={(e) => setChoosenImage(e.target.files[0])}
                                                        />
                                                        <i className="fa fa-camera"></i>
                                                    </div>
                                                    : ""
                                            }
                                        </>
                                    )}
                                    <span
                                        className="mx-4"
                                        style={{
                                            fontWeight: '500',
                                            fontSize: '1rem',
                                            color: '#525252',
                                            margin: '0 0 5px 0',
                                            padding: 0,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <Translator path={'pages.tokenProposal.image'} />
                                        <span className="obrigatory">*</span>
                                    </span>
                                    {errors.image && <div className="text-danger fs-12">{errors.image}</div>}
                                </div>
                                <div className="row col-12">
                                    <div className="col-lg-6 col-md-12">
                                        <InputText
                                            className={`${isPreviewProposal ? 'token-input-disabled' : ''}`}
                                            text={'pages.tokenProposal.name'}
                                            setState={setTokenName}
                                            value={tokenName}
                                            readOnly={isPreviewProposal ? true : false}
                                            maxLength={'16'}
                                        />
                                        {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                        {isPreviewProposal && changedFields.includes('name') ? (
                                            <div className="altered-text">
                                                <Translator path={'pages.tokenProposal.counterProposal.altered'} />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <InputText
                                            text={'pages.tokenProposal.invoiceValue'}
                                            setState={setInvoiceValueWithMask}
                                            value={invoiceValue && !invoiceValue.includes('NaN') ? invoiceValue : 0}
                                            className={`${isPreviewProposal ? 'token-input-disabled' : ''}`}
                                            readOnly={isPreviewProposal ? true : false}
                                        />
                                        {errors.invoiceValue && <div className="text-danger fs-12">{errors.invoiceValue}</div>}
                                        {isPreviewProposal && changedFields.includes('invoiceValue') ? (
                                            <div className="altered-text">
                                                <Translator path={'pages.tokenProposal.counterProposal.altered'} />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="d-flex flex-row text-input-container">
                                            <p className="text-input-label">
                                                <Translator path={'pages.tokenProposal.fundingDateLimit'} />
                                            </p>
                                            <p>*</p>
                                        </div>
                                        <DatePicker
                                            selected={fundingLimitDate}
                                            onChange={(date) => setFundingLimitDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className={`date-picker col-12 d-flex align-items-start form-control ${isPreviewProposal ? 'token-input-disabled' : ''
                                                }`}
                                            readOnly={isPreviewProposal ? true : false}
                                            startDate={new Date()}
                                            minDate={new Date()}
                                        />
                                        {errors.fundingLimitDate && <div className="text-danger fs-12">{errors.fundingLimitDate}</div>}
                                        {isPreviewProposal && changedFields.includes('fundingLimitDate') ? (
                                            <div className="altered-text">
                                                <Translator path={'pages.tokenProposal.counterProposal.altered'} />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="d-flex flex-row text-input-container">
                                            <p className="text-input-label">
                                                <Translator path={'pages.tokenProposal.liquidationDate'} />
                                            </p>
                                            <p>*</p>
                                        </div>
                                        <DatePicker
                                            selected={liquidationDate}
                                            onChange={(date) => setLiquidationDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className={`date-picker col-12 d-flex align-items-start form-control ${isPreviewProposal ? 'token-input-disabled' : ''
                                                }`}
                                            readOnly={isPreviewProposal ? true : false}
                                            startDate={fundingLimitDate}
                                            minDate={fundingLimitDate}
                                        />

                                        {errors.liquidationDate && <div className="text-danger fs-12">{errors.liquidationDate}</div>}
                                        {isPreviewProposal && changedFields.includes('liquidationDate') ? (
                                            <div className="altered-text">
                                                <Translator path={'pages.tokenProposal.counterProposal.altered'} />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="col-12 d-flex flex-column justify-content-start">
                                            <div className="d-flex flex-row text-input-container">
                                                <p className="text-input-label">
                                                    <Translator path={'pages.tokenProposal.discountPercentage'} />
                                                </p>
                                                <p>*</p>
                                            </div>
                                            <div className="position-relative">
                                                <Form.Select
                                                    className={`form-control ${isPreviewProposal ? 'token-input-disabled' : ''}`}
                                                    onChange={(e) => setDiscountPercentage(e.target.value)}
                                                    value={discountPercentage}
                                                    disabled={isPreviewProposal}
                                                >
                                                    <option value=""></option>
                                                    <option value="0.03">3%</option>
                                                    <option value="0.035">3.5%</option>
                                                    <option value="0.04">4%</option>
                                                </Form.Select>
                                                <i className="material-icons position-absolute select-discount">expand_more</i>
                                            </div>
                                            {errors.discountPercentage && (
                                                <div className="text-danger fs-12">{errors.discountPercentage}</div>
                                            )}
                                            {isPreviewProposal && changedFields.includes('discountPercentage') ? (
                                                <div className="altered-text">
                                                    <Translator path={'pages.tokenProposal.counterProposal.altered'} />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <InputText
                                            className="token-input-disabled"
                                            text={'pages.tokenProposal.valueToReceive'}
                                            readOnly={true}
                                            value={valueToReceive && !valueToReceive.includes('NaN') ? valueToReceive : 0}
                                        />
                                    </div>
                                </div>
                                <div className="row col-12"></div>
                                <div className="row col-12 p-0">
                                    <div className="row col-12 p-0">
                                        <div className="col-12 d-flex flex-column justify-content-start p-0 padding-left">
                                            <div className="d-flex flex-row text-input-container">
                                                <p className="text-input-label">
                                                    <Translator path={'pages.tokenProposal.description'} />
                                                </p>
                                                <p>*</p>
                                            </div>
                                            <textarea
                                                type="textarea"
                                                className={`form-control col-12 d-flex align-items-start ${isPreviewProposal ? 'token-input-disabled' : ''
                                                    }`}
                                                onChange={(e) => setDescription(e.target.value)}
                                                style={{ height: '9.5vh' }}
                                                value={description}
                                                readOnly={isPreviewProposal ? true : false}
                                            />
                                            {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                                            {isPreviewProposal && changedFields.includes('description') ? (
                                                <div className="altered-text">
                                                    <Translator path={'pages.tokenProposal.counterProposal.altered'} />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 additional-docs">
                                    {additionalDocs.map((doc, index) => (
                                        <div key={index} className="row col-lg-12 justify-content-center">
                                            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                <div className="position-relative d-flex flex-column justify-content-start">
                                                    <div className="d-flex flex-row text-input-container">
                                                        <p className="text-input-label" style={{ wordWrap: 'normal' }}>
                                                            <Translator path="pages.tokenProposal.additionalDoc" />
                                                            {<span> ({index + 1})</span>}
                                                            {index === 0 ? <span className="obrigatory">*</span> : ''}
                                                        </p>
                                                        <p></p>
                                                    </div>
                                                    <input
                                                        name="link"
                                                        type={inputType}
                                                        placeholder={`${doc.link ? doc.link.split('/')[1] : ''}`}
                                                        className={`col-10 d-flex align-items-start form-control hide-label ${isPreviewProposal ? 'token-input-disabled' : ''
                                                            }`}
                                                        onClick={isPreviewProposal ? () => { } : () => setInputType('file')}
                                                        style={{ overflow: 'hidden', paddingRight: '25px' }}
                                                        readOnly={isPreviewProposal ? true : false}
                                                    />
                                                    <i
                                                        className="material-icons icon-float-right hide-icon-issuer"
                                                    >
                                                        attach_file
                                                    </i>
                                                    {errors.fiscalDoc !== '' ? <div className="text-danger fs-12">{errors.fiscalDoc}</div> : ''}
                                                </div>
                                            </div>
                                            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-10">
                                                <div className="d-flex flex-column justify-content-start" style={{ padding: 0 }}>
                                                    <div className="d-flex flex-row text-input-container">
                                                        <p className="text-input-label">
                                                            <Translator path="pages.tokenProposal.additionalDocDescription" />
                                                            {<span> ({index + 1})</span>}
                                                            {index === 0 ? <span className="obrigatory">*</span> : ''}
                                                        </p>
                                                        <p></p>
                                                    </div>
                                                    <input
                                                        key={index}
                                                        name="description"
                                                        value={additionalDocs[index].description}
                                                        className={`col-10 d-flex align-items-start form-control ${isPreviewProposal ? 'token-input-disabled' : ''
                                                            }`}
                                                        // onChange={(e) => handleAddDocDescription(index, e)}
                                                        readOnly={isPreviewProposal ? true : false}
                                                    />
                                                    {errors.fiscalDocDesc !== '' ? (
                                                        <div className="text-danger fs-12">{errors.fiscalDoc}</div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    ))}

                                    <div className="row col-12" style={{ padding: 0, margin: '0 0 0.5rem 0' }}>
                                        <div className="col-sm-6 paddingLeft-control-issuer">
                                            {errors.link && <div className="text-danger fs-12">{errors.link}</div>}
                                        </div>
                                        <div className="col-sm-6 paddingRight-control-issuer">
                                            {errors.docDescription && <div className="text-danger fs-12">{errors.docDescription}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="actions actions-proposal-edit col-lg-12">
                                    {proposalStatus === 'IN_ANALYSIS' || proposalStatus === 'PRE_ACCEPTED' ? (
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            className="text-center col-xl-6 col-md-2 text-nowrap w-auto"
                                            id="btn"
                                            type={isPreviewProposal ? 'button' : 'submit'}
                                            onClick={isPreviewProposal ? onApprove : null}
                                        >
                                            {
                                                isPreviewProposal ? (
                                                    <Translator path={'pages.tokenProposal.approve'} />
                                                ) : (
                                                    <Translator path={'pages.tokenProposal.sendCounterPropose'} />
                                                )
                                            }
                                        </Button>
                                    ) : ""}
                                    {proposalStatus === 'IN_ANALYSIS' ? (
                                        <Button
                                            className="text-nowrap w-auto"
                                            variant="outline-primary"
                                            size="lg"
                                            id="btn-outlined"
                                            onClick={
                                                () => setIsPreviewProposal(!isPreviewProposal)
                                            }
                                        >
                                            {isPreviewProposal ? (
                                                <Translator path={'pages.tokenProposal.counterPropose'} />
                                            ) : (
                                                <Translator path={'pages.tokenProposal.cancel'} />
                                            )}
                                        </Button>
                                    ) : ""}

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ProposalDetails;
