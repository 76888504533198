import { emptyInvestors } from '@assets';
import { Loading, Table, TokenDetails, Translator } from '@components';
import { useInvestors } from '@contexts-admin';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { COLUMNS } from './TableColumns';
import Pagination from '@mui/material/Pagination';

export const Investors = () => {
  const { getInvestors, loading, investors, page, setPage, totalPages, } = useInvestors();
  const navigate = useNavigate();

  useEffect(() => {
    getInvestors(page, 'physical person');

  }, [page, setPage]);


  const handleChange = (event, value) => {
    setPage(value)
  };


  if (loading) {
    return <Loading />;
  }

  if (investors?.length > 0)
    return (
      <div className="page-wrapper tokens-list">
        <div className="d-flex title">
          <p>
            <Translator path={'pages.investors.title'} />
          </p>
          {/* // todo: Adicionar filtros
           */}

        </div>
        <Table
          props={{
            tableData: investors,
            tableColumns: COLUMNS,
            emptyImage: emptyInvestors,
          }}
        ></Table>

        <div className='pagination-div'>
          <Pagination
            count={totalPages}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>

      </div>
    );
};

export default Investors;
