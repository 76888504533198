import { createContext, useCallback, useContext, useState } from 'react';
import * as issuersService from '../../services/Admin/issuersService';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Translator } from '@components';
import { useNavigate } from 'react-router-dom';

export const AdminIssuersContext = createContext({});

const AdminIssuersProvider = ({ children }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [issuers, setIssuers] = useState();
  const [issuer, setIssuer] = useState();
  const [page, setPage] = useState();
  const [totalPages, setTotalPages] = useState(0);

  const getIssuers = useCallback(async (page, type) => {
    setLoading(true);

    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 12,
        type,
      };

      const { data } = await issuersService.getAllIssuers(params);
      setTotalPages(Math.ceil(data.total / params.pageSize));
      const formatedUsers = data.data.map((issuer) => {
        return {
          ...issuer, statusFormated: t(`types.issuerTypes.${issuer.status}`),
          action: (
            <Button
              className="text-nowrap w-auto"
              variant="outline-primary"
              size="lg"
              id="btn-outlined"
              onClick={
                () => {
                  navigate(`/issuers/${issuer.issuerId}`);
                }
              }
            >
              <Translator path={'pages.issuers.details'} />
            </Button>
          )
        };
      }
      );
      setIssuers(formatedUsers);
    } catch (error) {
      const errTitle = t('pages.issuers.apiErr.title');
      const errBody = t('pages.issuers.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getIssuerDetail = useCallback(async (issuerId) => {
    setLoading(true);
    try {
      const data = await issuersService.getIssuer(issuerId);
      setIssuer(data.data);
    } catch (error) {
      const errTitle = t('pages.issuers.apiErr.title');
      const errBody = t('pages.issuers.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const approveIssuer = useCallback(async (issuerId) => {
    setLoading(true);
    try {
      const data = await issuersService.approveIssuer(issuerId);
      const successHeader = t('pages.issuers.approveSwal.success');
      const successMessage = t('pages.issuers.approveSwal.sucessMessage');
      swal(successHeader, successMessage, 'success');
      getIssuerDetail(issuerId);
    } catch (error) {
      const errTitle = t('pages.issuers.approveError.title');
      const errBody = t('pages.issuers.approveError.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);


  return (
    <AdminIssuersContext.Provider
      value={{
        loading,
        issuers,
        getIssuers,
        setIssuers,
        totalPages,
        page,
        setPage,
        issuer,
        getIssuerDetail,
        approveIssuer,
      }}
    >
      {children}
    </AdminIssuersContext.Provider>
  );
};

const useAdminIssuers = () => {
  return useContext(AdminIssuersContext);
};

export { AdminIssuersProvider, useAdminIssuers };
