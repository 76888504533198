import Translator from '../../../../../components/I18n/Translator';

export const COLUMNS = [

  {
    Header: '',
    Footer: '',
    accessor: 'checkBox',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.userId'} />,
    Footer: <Translator path={'pages.investorGroups.userId'} />,
    accessor: 'userId',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.name'} />,
    Footer: <Translator path={'pages.investorGroups.name'} />,
    accessor: 'name',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.type'} />,
    Footer: <Translator path={'pages.investorGroups.type'} />,
    accessor: 'typeFormated',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.cpfCnpj'} />,
    Footer: <Translator path={'pages.investorGroups.cpfCnpj'} />,
    accessor: 'cpfCnpj',
    isSortable: false,
  },
];
