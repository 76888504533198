import { emptyInvestors } from '@assets';
import { Loading, Translator, Table } from '@components';
import { useInvestorGroups, useInvestorGroupsInvestors } from '@contexts-admin';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { COLUMNS } from './TableColumns';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';

const InvestorGroupInvestorList = ({ }) => {
  const { setShowModalVinculationInvestor } = useInvestorGroups();

  const { t } = useTranslation();
  const [investorGroupInvestorsFormated, setInvestorGroupInvestorsFormated] = useState()
  const { investorGroupId } = useParams();
  const {
    getInvestorGroupInvestors,
    investorGroupInvestors,
    loadingInvestorGroupInvestors,
    setPageInvestorsList,
    pageInvestorsList,
    totalPageInvestorsLists,
    selectedUsersRemove,
    setSelectedUserRemove,
    removeInvestors,
    removingInvestors,
  } = useInvestorGroupsInvestors();
  useEffect(() => {
    getInvestorGroupInvestors(investorGroupId, pageInvestorsList);
  }, [pageInvestorsList, setPageInvestorsList]);

  useEffect(() => {
    if (investorGroupInvestors) {
      const formatedUsers = investorGroupInvestors.map((investor) => {
        return {
          ...investor,
          typeFormated: t(`types.investorTypes.${investor.type.replace(/ /g, '')}`),
          name: investor.legalUser ? investor.legalUser.corporateName : investor.physicalUser.name,
          cpfCnpj: investor.legalUser ? investor.legalUser.cnpj : investor.physicalUser.cpf,
          checkBox: <input
            type="checkbox"
            className="form-check-input"
            id="customCheckBox2"
            checked={selectedUsersRemove.includes(investor.userId)}
            onClick={() => { handleChangeCheckBox(investor) }}
          />
        };
      }
      );
      setInvestorGroupInvestorsFormated(formatedUsers);
    }
  }, [investorGroupInvestors, selectedUsersRemove]);

  const handleChangeCheckBox = (
    investor
  ) => {
    if (selectedUsersRemove.includes(investor.userId)) {
      setSelectedUserRemove(selectedUsersRemove.filter(item => item !== investor.userId));
    } else {
      setSelectedUserRemove([...selectedUsersRemove, investor.userId])
    }
  }

  const handleChange = (event, value) => {
    setPageInvestorsList(value)
  };

  const handleVinculate = () => {
    setShowModalVinculationInvestor(true)

  }
  const handleRemove = () => {
    removeInvestors(investorGroupId, selectedUsersRemove)
  }

  if (loadingInvestorGroupInvestors || removingInvestors) {
    return <Loading />;
  }

  return (
    <div className="page-wrapper tokens-list">
      <div className="d-flex align-items-center pe-5 col-4">

        <Button variant="outlined" className={(selectedUsersRemove?.length || 0) ? "d-flex align-items-center " : "d-flex align-items-center disabled"} id="btn" onClick={(selectedUsersRemove?.length || 0) > 0 ? handleRemove : null}>
          <i className=" material-icons">close</i>
          <Translator path="pages.investorGroups.removeInvestor" />
        </Button>
        <Button className="d-flex align-items-center " onClick={handleVinculate}>
          <i className="material-icons">check</i>
          <Translator path="pages.investorGroups.vinculateInvestor" />
        </Button>
      </div>

      <div>

        <Table
          props={{
            tableData: investorGroupInvestorsFormated || [],
            tableColumns: COLUMNS,
            emptyImage: emptyInvestors,
          }}
        ></Table>

        <div className='pagination-div'>
          <Pagination
            count={totalPageInvestorsLists}
            page={pageInvestorsList}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      </div>

    </div>
  );
}
export default InvestorGroupInvestorList;