import React, { Fragment } from "react";



import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import Translator from "../../../components/I18n/Translator";
import InvestorGroupInfo from "./InvestorGroupInfo";
import InvestorGroupInvestorList from "./InvestorGroupInvestorList";
import InvestorGroupTokenList from "./InvestorGroupTokenList";



export const InvestorGroupDetails = () => {


  const tabData = [
    {
      name: "pages.investorGroups.details",
      icon: "home",
      content:
        <InvestorGroupInfo />,
    },
    {
      name: "pages.investorGroups.investors",
      icon: "group",
      content:
        <InvestorGroupInvestorList />,
    },
    {
      name: "pages.investorGroups.tokens",
      icon: "tokens",
      content:
        <InvestorGroupTokenList />
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className="default-tab">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} me-2`} />
                          <Translator path={data.name} />
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        <p>{data.content}</p>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Fragment>
  );
};


export default InvestorGroupDetails;
