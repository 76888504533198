import { Loading, Translator, UserImagePreview } from '@components';
import { useInvestorGroups } from '@contexts-admin';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VinculateInvestorModal from '../VinculateInvestorModal';
import VinculateTokenModal from '../VinculateTokenModal';


const InvestorGroupInfo = ({ }) => {

  const { investorGroupId } = useParams();
  const { getInvestorGroupDetail, investorGroup, loadingInvestorGroup } = useInvestorGroups();
  useEffect(() => {
    getInvestorGroupDetail(investorGroupId);
  }, []);


  if (loadingInvestorGroup) {
    return <Loading />;
  }

  return (
    <div className="col-xl-12 col-md-12">
      <div className="card justify-content-center">
        <div className="card-body d-flex">
          <UserImagePreview url={investorGroup?.image} />
          <div className="w-100 ps-4">
            <div className="d-flex justify-content-between">
              <div className="">
                <h4 className="font-w700"> {investorGroup?.name} </h4>
              </div>
            </div>
            {
              investorGroup?.description ?
                <div>
                  <div className="d-flex align-items-center pe-4 ">
                    <h6> <Translator path="pages.investorGroups.description" /></h6>
                  </div>
                  <div className="d-flex align-items-center pe-4 mb-2">
                    <span>
                      {` ${investorGroup?.description}`}
                    </span>
                  </div>
                </div>
                : ""
            }
          </div>
        </div>
      </div>
      <VinculateInvestorModal />
      <VinculateTokenModal />
    </div>

  )
}

export default InvestorGroupInfo;