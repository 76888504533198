import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import investorTranslations from './locales/investor';
import issuerTranslations from './locales/issuer';
import adminTranslations from './locales/admin';

const i18nConfig =
  process.env.REACT_APP_BUILD_TARGET === 'investor'
    ? {
        resources: investorTranslations,
        fallbackLng: 'pt-BR',
        ns: ['investor'],

        interpolation: {
          escapeValue: false,
        },
      }
    : process.env.REACT_APP_BUILD_TARGET === 'admin'
    ? {
        resources: adminTranslations,
        fallbackLng: 'pt-BR',
        ns: ['admin'],

        interpolation: {
          escapeValue: false,
        },
      }
    :{
        resources: issuerTranslations,
        fallbackLng: 'pt-BR',
        ns: ['issuer'],

        interpolation: {
          escapeValue: false,
        },
      };

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
