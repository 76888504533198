import { emptyProposals } from '@assets';
import { Loading, Table, Translator, StatusCard } from '@components';
import { useAdminTokenProposal } from '@contexts-admin';
import { formatDate } from 'date-utils-2020';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { COLUMNS } from './TableColumns';

export const AdminProposalList = () => {
  const [proposals, setProposals] = useState([]);

  const { getAllProposals, allProposals, loading } = useAdminTokenProposal();
  const navigate = useNavigate();

  useEffect(() => {
    getAllProposals();

  }, []);

  useEffect(() => {
    if (allProposals) {
      const proposals = allProposals.map((proposal) => {
        const actions = (
          <Button
            className="text-nowrap w-auto"
            variant="outline-primary"
            size="lg"
            id="btn-outlined"
            onClick={
              () => {
                navigate(`/tokenProposal/${proposal.tokenProposalId}`);
              }
            }
          >
            <Translator path={'components.proposalsList.details'} />
          </Button>
        );
        return {
          ...proposal,
          formattedInvoiceValue: `${proposal.invoiceValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}`,
          formattedValueToReceive: `${proposal.valueToReceive.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}`,
          formattedFundingDate: formatDate(proposal.fundingLimitDate, 'dd/MM/yyyy'),
          formattedLiquidationDate: formatDate(proposal.liquidationDate, 'dd/MM/yyyy'),
          formattedStatus: <StatusCard status={proposal.status} />,
          actions: actions,
        };
      });

      setProposals(proposals);
    }
  }, [allProposals]);



  if (loading) {
    return <Loading />;
  }

  if (allProposals) {
    return (
      <div className="page-wrapper">
        <Table
          props={{
            tableData: proposals,
            tableColumns: COLUMNS,
            emptyImage: emptyProposals,
          }}
        ></Table>

      </div>
    );
  }
};


export default AdminProposalList;