import Translator from '../../../../../components/I18n/Translator';

export const COLUMNS = [

  {
    Header: '',
    Footer: '',
    accessor: 'checkBox',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.tokenId'} />,
    Footer: <Translator path={'pages.investorGroups.tokenId'} />,
    accessor: 'tokenId',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.name'} />,
    Footer: <Translator path={'pages.investorGroups.name'} />,
    accessor: 'name',
    isSortable: false,
  },

];
