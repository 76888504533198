export default {
  issuer: {
    pages: {
      register: {
        firstStep: {
          header: 'Cadastro - Etapa 1',
          body: 'Por favor, preencha as informações abaixo',
          cnpj: 'CNPJ',
          name: 'Nome completo',
          cpf: 'CPF',
          email: 'E-mail',
          phone: 'Telefone',
          goBack: 'Voltar',
          continue: 'Continuar',
          resend: 'Token enviado com sucesso!',
          validationErr: {
            noCnpj: 'Por favor informe seu cnpj',
            invalidCnpj: 'Por favor informe um cnpj válido',
            noName: 'Por favor informe seu nome completo',
            noCpf: 'Por favor informe seu cpf',
            invalidCpf: 'Por favor informe um cpf válido',
            noEmail: 'Por favor informe seu e-mail',
            noPhone: 'Por favor informe seu número de telefone',
            invalidPhone: 'Por favor informe um número de telefone válido',
            invalidEmail: 'Por favor informe um e-mail válido',
          },
          apiErr: {
            title: 'Falha ao registrar',
            dupEmail: 'Email já existe',
            dupCpf: 'Cpf já existe',
            dupCnpj: 'Cnpj já existe',
            dupPhone: 'Número de telefone já existe',
          },
        },
        secondStep: {
          header: 'Cadastro - Etapa 2',
          body: 'Por favor, insira o código enviado em seu e-mail',
          send: 'Reenviar',
          code: 'código?',
          goBack: 'Voltar',
          continue: 'Continuar',
          apiErr: {
            title: 'Falha ao validar código',
            tokenErr: 'Por favor informe o código enviado em seu e-mail!',
          },
        },
        finalStep: {
          header: 'Cadastro - Etapa 3',
          body: 'Por favor, defina uma senha',
          password: 'Senha',
          confirm: 'Confirmar senha',
          goback: 'Voltar',
          submit: 'Finalizar',
          errors: {
            pass: 'Por favor informe sua senha!',
            confirm: 'Por favor confirme sua senha!',
            val: 'As senhas não conferem!',
            attend: 'Por favor atenda a todos os requisitos para definir sua senha!',
          },
          validators: {
            lenght: 'Mínimo de 8 caracteres',
            num: 'Obrigatório ter pelo menos um número',
            upper: 'Mínimo uma letra maiuscula',
            special: 'Um caractere especial:!@#$%^&*(),.?":{}|<>] ',
            equal: 'Ambas as senhas são iguais',
          },
          validationErr: {
            password: 'Por favor informe uma senha',
            confirm: 'Por favor confirme a sua senha',
          },
          success: 'Sucesso',
          sucessMessage: 'A operação foi bem sucedida',
        },
      },
      login: {
        title: 'Olá, emissor!',
        body: 'Por favor, digite o seu e-mail e sua senha.',
        email: 'E-mail',
        password: 'Senha',
        submit: 'Entrar',
        alter: 'Alterar senha',
        noAccount: 'Não possui uma conta?',
        register: 'Cadastrar',
        loading: 'Carregando...',
        validationErr: {
          email: 'Por favor, insira seu email',
          password: 'Por favor, insira sua senha',
          recaptcha: 'Recaptcha inválido'
        },
        apiErr: {
          title: 'Falha ao fazer login',
          login: 'Por favor check suas credenciais',
        },
      },
      issuer: {
        title: 'Cadastro do emissor',
        logo: 'Logo da empresa',
        cnpj: 'CNPJ',
        social: 'Razão social',
        corporateName: 'Nome da empresa',
        cep: 'CEP',
        corporateEmail: 'E-mail da empresa',
        selectedSocialContract: 'Contrato social',
        selectedAddtionalDoc: 'Documento',
        phone: 'Telefone',
        foundingDate: 'Data da fundação',
        simpleDescription: 'Breve descrição da empresa',
        description: 'Descrição detalhada da empresa',
        contract: 'Contrato social/Procuração',
        capital: 'Capital social',
        url: 'URL do Website',
        area: 'Ramo',
        docs: 'Documento adicional',
        docDescription: 'Descrição do documento adicionado',
        register: 'Cadastrar',
        edit: 'Editar',
        save: 'Salvar alterações',
        alter: 'Enviar alterações',
        cancel: 'Cancelar alterações',
        addDoc: 'Adicionar outro documento',
        removeDoc: 'Excluir este documento adicional',
        downloadDoc: 'Baixar documento',
        alterApproved: 'Caso altere os dados, nosso time irá reavaliar suas informações',
        finishRegister:
          'Estamos quase lá! ! Agora as informações do emissor estão sendo verificadas, por favor aguarde os próximos passos em seu e-mail:',
        goBack: 'Voltar',
        validators: {
          logo: 'Por favor insira um logo',
          social: 'Por favor informe uma razão social',
          corporateName: 'Por favor informe o nome da empresa',
          cep: 'Por favor informe o CEP da empresa',
          corporateEmail: 'Por favor informe o e-mail da empresa',
          phone: 'Por favor informe o telefone da empresa',
          foundingDate: 'Por favor informe a data de fundação',
          simpleDescription: 'Por favor insira uma descrição simples',
          description: 'Por favor insira uma descrição detalhada',
          contract: 'Por favor insira um contrato social',
          capital: 'Por favor informe o capital social da empresa',
          url: 'Por favor informe o site da empresa',
          area: 'Por favor informe o ramo de atuação',
        },
        isEdit: {
          link: 'Nenhum arquivo selecionado',
        },
        err: {
          noLink: 'Por favor adicione um documento para adicionar outro campo',
          noDesc: 'Por favor adicione uma descrição para o documento',
          noRemove: 'Não há documentos para remover',
          original: 'Não é possivel remover documentos do cadastro original, apenas altera-los!',
        },
        apiErr: {
          title: 'Falha ao registrar emissor',
          body: 'Tente novamente mais tarde.',
        },
        success: {
          title: 'Sucesso',
          body: 'Emissor cadastrado com sucesso',
          editBody: 'Dados do emissor editados com sucesso',
        },
      },
      myBank: {
        title: 'Dados bancários',
        form: {
          cnpj: 'CNPJ',
          bank: 'Banco',
          type: 'Tipo de conta',
          agency: 'Agência',
          account: 'Conta',
          digit: 'Digito',
          keyType: 'Tipo de chave Pix',
          key: 'Chave Pix',
          submit: 'Cadastrar',
          edit: 'Editar',
          send: 'Enviar alterações',
          alter: 'Salvar alterações',
          cancel: 'Cancelar alterações',
        },
        typeSelect: {
          current: 'Corrente',
          poup: 'Poupança',
        },
        pixSelect: {
          email: 'E-mail',
          phone: 'Celular',
          random: 'Aleatório',
          cnpj: 'CNPJ',
        },
        validators: {
          bankCode: 'Por favor selecione um banco brasileiro',
          accountType: 'Por favor informe o tipo da conta',
          agency: 'Por favor insira o número da sua agência',
          agencyDigit: 'Por favor insira o dígito de sua agência',
          accountNumber: 'Por favor insira o número da conta',
          accountNumberDigit: 'Por favor insira o dígito da sua conta',
          pixTypeKey: 'Por favor selecione um tipo de chave pix',
          pixKey: 'Por favor informe uma chave pix',
        },
        err: {
          titleReg: 'Erro ao cadastrar dados bancários',
          titleEdit: 'Erro ao editar dados bancários',
          body: 'Por favor tente novamente mais tarde!',
        },
        instructions: {
          title: 'Atenção as instruções abaixo',
          block: 'Preencha com dados reais de sua empresa para sacar e depositar com segurança.',
          business: 'Não utilize seus dados bancários pessoas. Todas informações devem ser de sua empresa.',
          email: 'Ao cadastrar os dados bancários de sua empresa, nossa plataforma verificará a autenticidade',
        },
        finish: {
          body: 'Seus dados bancários estão sendo avaliados pelo nosso time!',
          end: 'Assim que for aprovado, iremos te avisar por e-mail.',
          goBack: 'Voltar',
        },
      },
      tokenProposal: {
        title: 'Proposta de token',
        image: 'Imagem do token',
        name: 'Nome do token (Máx: 16 caracteres)',
        invoiceValue: 'Valor total da nota fiscal',
        fundingDateLimit: 'Data de recebimento (Saque)',
        liquidationDate: 'Data de pagamento (Depósito)',
        discountPercentage: 'Taxa de desconto',
        valueToReceive: 'Valor a receber',
        description: 'Descrição do token',
        additionalDoc: 'Documento fiscal/Nota fiscal',
        additionalDocDescription: 'Descrição do documento fiscal',
        sendProposal: 'Enviar proposta',
        addDoc: 'Adicionar outro documento',
        preview: 'Pré-visualização',
        remove: 'Remover este documento',
        notApproved: {
          text: 'Para realizar uma proposta, é necessário finalizar o cadastro antes! Por favor, clique no botão abaixo e faça isso agora mesmo.',
          complete: 'Finalizar cadastro',
        },
        missApprove: {
          text: 'Para realizar uma proposta, nosso time precisa aprovar as informações preenchidas no cadastro! Enviaremos um e-mail quando os dados forem aprovados. Por favor, aguarde.',
        },
        changesRequested: {
          body: 'Para realizar uma proposta, é necessário corrigir os dados cadastrais da empresa! Por favor, clique no botão abaixo e faça isto agora.',
          button: 'Corrigir cadastro',
        },
        rejected: {
          body: 'Infelizmente os dados cadastrais da sua empresa foram rejeitados! Por favor, clique no botão abaixo e faça o cadastro novamente.',
        },
        errors: {
          noName: 'Por favor informe o nome do token.',
          noInvoice: 'Por favor informe o valor total da nota fiscal.',
          noFundingLimitDate: 'Por favor informe a data de recebimento.',
          noLiquidationDate: 'Por favor informe a data de pagamento.',
          noDiscountPercentage: 'Por favor informe a taxa de desconto.',
          noDescription: 'Por favor informe a descrição do token.',
          noImage: 'Por favor informe a imagem do token.',
          noFiscalDoc: 'Por favor informe o documento fiscal.',
          noFiscalDesc: 'Por favor informe a descrição do documento fiscal.',
          noAdditionalDoc: 'Por favor informe um documento antes de adicionar outro.',
          noAdditionalDocDesc: 'Por favor descreva o documento adicional antes de adicionar outro.',
          fileTooLargeTitle: 'Arquivo muito grande',
          fileTooLargeBody: 'Esse arquivo é muito grande, por favor adicione outro.',
        },
        apiErr: {
          title: 'Falha ao submeter proposta de token',
          body: 'Tente novamente mais tarde.',
          upload: 'Falha ao fazer upload da imagem.',
          download: 'Falha ao baixar a imagem.',
          docUpload: 'Falha ao fazer upload do documento',
          update: 'Falha ao atualizar proposta',
          updateBody: 'Tente novamente mais tarde ou contate um administrador.',
          preApprove: 'Falha ao aceitar proposta',
          annul: 'Falha ao anular proposta',
        },
        success: {
          title: 'Sucesso',
          body: 'Proposta de token submetida com sucesso',
          uploadBody: 'Imagem do token adicionada',
        },
        overviewModal: {
          overview: 'Resumo da proposta',
          issuer: 'Emissor:',
          invoice: 'Valor da nota fiscal',
          valueToReceive: 'Valor a receber',
          discountPercentage: 'Taxa de desconto',
          fundingDate: 'Data de recebimento (saque)',
          liquidationDate: 'Data de pagamento (depósito)',
          cancel: 'Cancelar',
          confirm: 'Confirmar',
        },
        successModal: {
          text: 'Estamos quase lá! Agora as informações do token estão sendo verificadas, por favor aguarde os próximos passos em seu email: ',
          seeProposal: 'Ver proposta',
        },
        proposalPreview: {
          title: 'Você está visualizando como investidor',
          currentBalance: 'Saldo atual: ',
          minimumValue: 'Valor mínimo',
          monthlyYield: 'Rendimento mensal',
          remainingQuantity: 'Tokens restantes',
          invest: 'Investir',
          tokenName: 'Nome do token:',
          unityPrice: 'Valor de 1 token:',
          quantity: 'Selecione a quantidade',
          valueToInvest: 'Valor a investir',
          valueToReceive: 'Valor a receber',
          receiveDate: 'Receba em 1x',
          description: 'Descrição',
          about: 'Sobre o token',
          info: 'Informações gerais',
          captation: 'Captação',
          currentCaptation: 'Captação atual',
          buyers: 'Compradores',
          liquidation: 'Liquidação',
          totalValue: 'Valor total',
          offeredTokens: 'Tokens ofertados',
          issuerInfo: 'Informações do emisor',
          foundationDate: 'Fundação: ',
        },
      },
      withdrawList: {
        title: 'Saques',
        filter: 'Filtros',
        resend: 'Token enviado!',
        codeFail: 'Por favor preencha todos os campos',
        body: 'Saque solicitado com sucesso! Por favor aguarde os próximos passos em seu email:',
        works: 'Dúvidas de como sacar?',
        filters: {
          dateIni: 'Data inicial - Prevista',
          dateFi: 'Data final - Prevista',
          withdrawDateIni: 'Data inicial - saque',
          withdrawDateFi: 'Data final - saque',
          code: 'Código do saque',
          name: 'Nome do token',
          tokenCode: 'Código do token',
          amount: 'Valor de saque',
          status: 'Status',
          cleanBtn: 'Limpar filtros',
          filter: 'Filtrar',
        },
        request: {
          title: 'Resumo da solicitação',
          titleRequested: 'Resumo do saque',
          requesting: 'Solicitar saque',
          name: 'Nome do token',
          issuer: 'Emissor',
          amount: 'Valor a sacar',
          requestedAmount: 'Valor sacado',
          date: 'Data da solicitação',
          dateRequested: 'Data do saque',
          info: 'Informações do destinatário',
          bank: 'Banco',
          accountType: 'Tipo de conta',
          agency: 'Agência',
          accountNumber: 'Conta',
          keyType: 'Tipo de chave Pix',
          pixKey: 'Chave pix',
          cancel: 'Cancelar',
          continue: 'Continuar',
          goBack: 'Voltar',
          finish: 'Finalizar',
          body: 'Por favor, insira o código enviado em seu e-mail:',
        },
        tableColumns: {
          withdrawCode: 'Código do saque',
          name: 'Nome do token',
          tokenCode: 'Código do token',
          val: 'Valor de saque',
          date: 'Data prevista',
          withdrawDate: 'Data de saque',
          status: 'Status',
          action: 'Ações',
          actionItems: {
            details: 'Detalhes do token',
            withdraw: 'Solicitar saque',
            account: 'Detalhes da conta',
            requested: 'Detalhes da solicitação',
          },
        },
        status: {
          available: 'Disponível',
          incapture: 'Em captação',
          analysing: 'Analisando conta',
          requested: 'Solicitado',
          done: 'Realizado',
          cancel: 'Cancelado',
        },
        worksModal: {
          bodyLabel: 'Passo a passo de como Sacar',
          stepOne: 'Confira na tabela se o ',
          stepOneComplementOne: '"Status"',
          stepOneComplementTwo: ' está',
          stepOneComplementThree: ' "Disponível"',
          stepTwo: 'Clique no ícone "',
          stepTwoComplementOne: '" na coluna ',
          stepTwoComplementTwo: '"Ações"',
          stepThree: 'Clique em ',
          stepThreeComplement: '"Solicitar saque"',
          submit: 'Ok, entendi!',
        },
      },
      tokensList: {
        title: 'Meus tokens',
        name: 'Nome',
        offeredValue: 'Valor a receber',
        fundingDate: 'Data de captação',
        liquidationDate: 'Data de liquidação',
        action: 'Ações',
        inCapture: 'Em captação',
        allSold: 'Captado',
        liquidating: 'Em liquidação',
        complete: 'Concluído',
        cancelled: 'Cancelado',

        actions: {
          detail: 'Detalhes do token',
          withdraw: 'Solicitar saque',
          deposit: 'Realizar depósito',
        },

        filtersModal: {
          button: 'Filtros',
          fundingInitial: 'Data inicial - Captação',
          fundingFinal: 'Data final - Captação',
          liquidationInitial: 'Data inicial - Liquidação',
          liquidationFinal: 'Data final - Liquidação',
          name: 'Nome',
          valueToReceive: 'Valor a receber',
          status: {
            inCapture: 'Em captação',
            allSold: 'Captado',
            complete: 'Concluído',
            cancelled: 'Cancelado',
            liquidating: 'Em liquidação',
          },
          clean: 'Limpar filtros',
          filter: 'Filtrar',
        },
      },
      depositList: {
        title: 'Depósito',
        titleRequested: 'Resumo do depósito',
        body: 'Seu depósito está sendo verificado pelo nosso time! Agora é só aguardar a nossa validação, para concluirmos seu depósito. Fique atento no seu e-mail para acompanhar as atualizações de seu depósito.',
        works: 'Dúvidas de como depositar?',
        choose: 'Escolha como deseja depositar',
        amount: 'Valor a depositar',
        requestedAmount: 'Valor depositado',
        dateRequested: 'Data do depósito',
        pixBtn: 'Depositar via Pix',
        tedBtn: 'Depositar via TED',
        cancel: 'Cancelar',
        goback: 'Voltar',
        finish: 'Depósito já realizado',
        paymentType: 'Realizado via',
        pix: {
          msg: 'Invista em Cripto ativos!',
          expire: 'Expira em ',
          or: 'ou',
          copy: 'Pix Copia e Cola',
          copied: 'Código pix copiado!',
        },
        worksModal: {
          bodyLabel: 'Passo a passo de como depositar',
          stepOne: 'Confira na tabela se o ',
          stepOneComplementOne: '"Status"',
          stepOneComplementTwo: ' está',
          stepOneComplementThree: ' "A depositar"',
          stepTwo: 'Clique no ícone "',
          stepTwoComplementOne: '" na coluna ',
          stepTwoComplementTwo: '"Ações"',
          stepThree: 'Clique em ',
          stepThreeComplement: '"Realizar depósito"',
          submit: 'Ok, entendi!',
        },
        actionItems: {
          ready: 'Realizar depósito',
          detailsPrev: 'Detalhes da solicitação',
          details: 'Detalhes do depósito',
        },
        status: {
          incapture: 'Em captação',
          waiting: 'A depositar',
          analysing: 'Em análise',
          done: 'Realizado',
          cancel: 'Cancelado',
        },
        tableColumns: {
          order: 'Código do depósito',
          name: 'Nome do token',
          tCode: 'Código do token',
          amount: 'Valor do depósito',
          pDate: 'Data prevista',
          date: 'Data do depósito',
          status: 'Status',
          action: 'Ações',
        },
        filter: 'Filtrar',
        filters: {
          dateIni: 'Data inicial - depósito',
          dateFi: 'Data final - depósito',
          order: 'Código do deposito',
        },
      },
      dashboard: {
        firstSteps: 'Primeiros passos',
        step1: {
          title: 'Dados cadastrais',
          actual: 'Passo 1 de 3',
          start: 'Por favor, clique no botão abaixo e insira os dados da sua empresa.',
          analysis: 'Os dados da sua empresa estão sendo analisados pelo nosso time.',
          rejected: 'Infelizmente, os dados cadastrais da sua empresa foram rejeitados.',
          change: 'Por favor, veja no seu e-mail as mudanças solicitadas.',
          approved: 'Os dados cadastrais da sua empresa foram aprovados.',
          startBtn: 'Começar',
          depositBtn: 'Depositar',
          analysisBtn: 'Em análise...',
          rejectedBtn: 'Corrigir cadastro',
          changeBtn: 'Alterar dados do cadastro',
          approvedBtn: 'Concluído',
          status: {
            analysis: 'Dados em análise',
            rejected: 'Dados rejeitados',
            change: 'Mudanças solicitadas',
            approved: 'Dados aprovados',
          },
        },
        step2: {
          title: 'Proposta de token',
          actual: 'Passo 2 de 3',
          waiting: 'Para começar o “Passo 2”. Por favor, conclua o “Passo 1” antes.',
          start: 'Crie sua primeira proposta de token em nossa plataforma.',
          analysis: 'Sua primeira proposta está sendo analisada pelo nosso time.',
          rejected: 'Infelizmente, sua primeira proposta de token foi rejeitada.',
          change: 'Por favor, veja no seu e-mail as mudanças solicitadas.',
          approved: 'Os dados da sua primeira proposta foram aprovadas.',
          preAccepted: 'Por favor, aguarde enquanto a proposta está sendo aprovada.',
          annulled: 'Proposta anulada! Clique no botão abaixo para enviar outra proposta.',
          waitingBtn: 'Aguardando...',
          startBtn: 'Fazer proposta',
          analysisBtn: 'Em análise...',
          rejectedBtn: 'Corrigir proposta',
          changeBtn: 'Alterar proposta',
          approvedBtn: 'Concluído',
          preAcceptedBtn: 'Ver proposta',
          annulledBtn: 'Enviar outra proposta',
          status: {
            analysis: 'Proposta em análise',
            rejected: 'Proposta rejeitada',
            change: 'Mudanças solicitadas',
            approved: 'Proposta aprovada',
            preAccepted: 'Pré-aprovada',
            annulled: 'Proposta anulada',
          },
        },
        step3: {
          title: 'Dados bancários',
          actual: 'Passo 3 de 3',
          waiting: 'Por favor, complete os passos anteriores.',
          start: 'Por favor, preencha os dados da conta de sua empresa.',
          analysis: 'Os dados bancários estão sendo analisados pelo nosso time.',
          rejected: 'Infelizmente, os dados bancários foram rejeitados.',
          change: 'Por favor, veja no seu e-mail as mudanças solicitadas.',
          approved: 'Os dados bancários da sua empresa foram aprovados.',
          waitingBtn: 'Aguardando...',
          startBtn: 'Inserir dados bancários',
          analysisBtn: 'Em análise...',
          rejectedBtn: 'Corrigir dados bancários',
          changeBtn: 'Alterar dados bancários',
          approvedBtn: 'Concluído',
          status: {
            analysis: 'Dados em análise',
            rejected: 'Dados rejeitados',
            change: 'Mudanças solicitadas',
            approved: 'Dados aprovados',
          },
        },
      },
      wallet: {
        depositBtn: 'Depositar',
        balance: 'Saldo atual:',
        return: 'Valor a restituir:',
        propolsal: 'Últimas propostas',

        linkComponent: {
          depositText: 'Desejo realizar um depósito',
          withdrawText: 'Desejo realizar um saque',
        },
        bankComponent: {
          title: 'Dados bancários',
          bank: 'Banco',
          typeA: 'Tipo da conta',
          agency: 'Agência',
          account: 'Conta',
          typeP: 'Tipo de chave Pix',
          key: 'Chave Pix',
        },
      },
      //TODO: separar as traduções do perfil e resetar senha
      profile: {
        name: 'Nome completo',
        cpf: 'CPF',
        editTitle: 'Dados cadastrais',
        email: 'E-mail',
        phone: 'Telefone',
        edit: 'Editar',
        photo: 'Foto de perfil',
        edit: 'Editar',
        title: 'Alterar senha',
        email: 'E-mail',
        continue: 'Continuar',
        goBack: 'Voltar',
        submit: 'Confirmar',
        goback: 'Voltar',
        success: {
          title: 'Sucesso',
          body: 'Usuário atualizado com sucesso!',
        },
        click: 'Clique aqui',
      },
      reset: {
        goback: 'Voltar',
        continue: 'Continuar',
        finish: 'Finalizar',
        firstStep: {
          title: 'Redefinir senha - Etapa 1',
          body: 'Por favor, informe seu e-mail',
          email: 'E-mail',
          err: {
            email: 'Insira seu e-mail!',
            valid: 'E-mail inválido',
          },
        },
        secondStep: {
          title: 'Redefinir senha - Etapa 2',
          body: 'Por favor, insira o código enviado em seu e-mail:',
          resend: 'Reenviar código',
        },
        finalStep: {
          title: 'Redefinir senha - Etapa 3',
          body: 'Por favor, defina uma nova senha',
          pass: 'Senha',
          confirm: 'Confirmar senha',
        },
        err: {
          emailTitle: 'E-mail não encontrado',
          emailBody: 'Por favor insira um email valido',
        },
      },
    },
    components: {
      select: {
        noOptions: 'Nenhum resultado encontrado',
      },
      tokenPropolsalCard: {
        captureDate: 'Data de captação',
        liquidDate: 'Data de liquidação',
        note: 'Valor da nota fiscal',
        toReceive: 'Valor a receber',
      },
      tableBody: {
        tokenProposaltokenslist:
          'Infelizmente, você não possui nenhum token em nossa plataforma! Por favor, clique no botão abaixo e faça uma proposta.',
        walletwithdrawList:
          'Infelizmente, você não possui nenhum token disponível para saque! Por favor, clique no botão abaixo e veja os status dos seus tokens.',
        walletdepositList:
          'Infelizmente, você não possui nenhum token disponível para depósito! Por favor, clique no botão abaixo e faça uma proposta de token.',
        tokenProposallist:
          'Infelizmente, você não realizou nenhuma proposta até o momento! Por favor, clique no botão abaixo e faça uma agora mesmo.',
      },
      tableButton: {
        tokenProposaltokenslist: 'Fazer proposta',
        walletwithdrawList: 'Meus tokens',
        walletdepositList: 'Fazer proposta',
        tokenProposallist: 'Fazer proposta',
      },
      footer: {
        title: 'Todos os direitos reservados por ',
        corporate: 'Criptech',
      },
      analysisCard: {
        analysis: 'Dados em análise',
        changes: 'Mudanças solicitadas',
        approved: 'Dados aprovados!',
        rejected: 'Corrija os dados',
      },
      statusCard: {
        captation: 'Captação',
        complete: 'Concluído',
        cancel: 'Cancelado',
        captated: 'Captado',
        ranOut: 'Esgotado',
        accepted: 'Aprovada',
        preAccepted: 'Pré-aprovada',
        analysis: 'Em análise',
        annuled: 'Anulada',
        negotiating: 'Negociação',
      },
      tokenCard: {
        value: 'Valor mínimo',
        revenue: 'Rendimento mensal',
        quantity: 'Tokens restantes',
        receivable: 'Recebível',
        percentage: 'Comparado ao CDI',
      },
      proposalsList: {
        name: 'Nome',
        invoiceValue: 'Valor da nota fiscal',
        valueToReceive: 'Valor a receber',
        fundingLimitDate: 'Data de captação',
        liquidationDate: 'Data de liquidação',
        actions: 'Ações',
        status: {
          inAnalysis: 'Em análise',
          negotiating: 'Negociação',
          approved: 'Aprovada',
          rejected: 'Rejeitada',
          preApproved: 'Pré-aprovada',
          annulled: 'Anulada',
        },
        action: {
          edit: 'Editar proposta',
          view: 'Ver nova proposta',
          detail: 'Detalhes do token',
        },
      },
      proposalFormEdit: {
        analysis: 'Dados em análise',
        save: 'Salvar alterações',
        cancel: 'Cancelar alterações',
        success: 'Proposta alterada',
        successBody: 'Proposta alterada com sucesso',
        remove: 'Remover este documento',
        noRemove: 'Não é possível remover',

        confirm: {
          title: 'Tem certeza?',
          text: 'Você tem certeza que deseja anular essa proposta e não continuar o processo de aprovação?',
          yes: 'Sim, desejo anular a proposta',
          no: 'Não',
          success: 'Sua proposta foi anulada com sucesso',
        },
      },
      counterProposal: {
        negotiating: 'Em negociação',
        accept: 'Aceitar contraproposta',
        edit: 'Editar contraproposta',
        annul: 'Anular proposta',
        send: 'Enviar nova proposta',
        altered: 'Este campo foi alterado',

        api: {
          preApprove: 'Proposta aceita com sucesso',
          preApproveBody:
            'A proposta está pré-aprovada, após aprovação dos administradores o token estará disponível para captação.',
          annul: 'Proposta anulada com sucesso',
          annulBody: 'Você acabou de anular uma proposta, cadastre outra.',
        },
      },
      footer: {
        title: 'Todos os direitos reservados por ',
        corporate: 'Criptech',
      },
      tokenDetails: {
        apiErr: {
          title: 'Falha ao buscar detalhes do token',
          body: 'Tente novamente mais tarde ou contate um administrador.',
        },
      },
    },
    titles: {
      dashboard: 'Painel',
      mycorporation: 'Minha empresa',
      mybank: 'Minha empresa',
      tokens: 'Tokens',
      wallet: 'Carteira',
      exit: 'Log out',
      profile: 'Meu perfil',
      logout: 'Sair',
      tokenProposal: 'Tokens',
    },
    menuLabel: {
      dashboard: 'Painel',
      issuer: 'Minha empresa',
      issuerItems: {
        myData: 'Dados cadastrais',
        myBank: 'Dados bancários',
      },
      tokens: 'Tokens',
      tokensItems: {
        newProposal: 'Nova proposta',
        myProposal: 'Minhas propostas',
        myTokens: 'Meus tokens',
      },
      wallet: 'Carteira',
      walletItems: {
        whithdrawList: 'Saque',
        depositList: 'Depósito',
      },
      profileItems: {
        registry: 'Dados cadastrais',
        alter: 'Alterar senha',
      },
      exit: 'Logout',
    },
    profile: {
      error: {
        title: 'Falha ao buscar informações do emissor',
        body: 'Por favor tente novamente ou contate um administrador',
      },
    },
  },
};
