import { Loading } from '@components';
import { useAdminIssuers } from '@contexts-admin';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Issuer from './Issuer';

export const IssuerDetails = () => {
  const { getIssuerDetail, issuer, loading, approveIssuer } = useAdminIssuers();
  const { userId } = useParams();

  useEffect(() => {
    getIssuerDetail(userId);
  }, []);

  const handleApprove = () => {

    approveIssuer(userId);
  };


  if (loading) {
    return <Loading />;
  }

  if (issuer) {

    return (
      <Issuer issuer={issuer} handleApprove={handleApprove} />
    );
  }
};

export default IssuerDetails;
