import Keycloack from 'keycloak-js';


console.log(process.env.REACT_APP_ANS_KEYCLOAK_JSON)
const keycloakConfig = JSON.parse(
  process.env.REACT_APP_ANS_KEYCLOAK_JSON ? process.env.REACT_APP_ANS_KEYCLOAK_JSON : '',
);
export const keycloak = Keycloack({
  url: keycloakConfig['auth-server-url'],
  realm: keycloakConfig['realm'],
  clientId: keycloakConfig['resource'],
});

export const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
};
