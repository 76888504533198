import { Loading } from '@components';
import { useInvestors } from '@contexts-admin';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PhysicalInvestor from './PhysicalInvestor';
import LegalInvestor from './LegalInvestor';

export const InvestorDetails = () => {
  const { getInvestorDetail, investor, loading, approveInvestor } = useInvestors();
  const { userId } = useParams();

  useEffect(() => {
    getInvestorDetail(userId);
  }, []);

  const handleApprove = () => {

    approveInvestor(userId);
  };


  if (loading) {
    return <Loading />;
  }

  if (investor?.physicalUser) {
    return (
      <PhysicalInvestor investor={investor} handleApprove={handleApprove} />
    );
  } else if (investor?.legalUser) {
    return (
      <LegalInvestor investor={investor} handleApprove={handleApprove} />
    );
  }
};

export default InvestorDetails;
