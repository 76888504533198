import { emptyInvestors } from '@assets';
import { Loading, Translator, Table } from '@components';
import { useInvestorGroups, useInvestorGroupsTokens } from '@contexts-admin';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { COLUMNS } from './TableColumns';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';

const InvestorGroupTokenList = ({ }) => {
  const { setShowModalVinculationTokens } = useInvestorGroups();

  const { t } = useTranslation();
  const [investorGroupTokensFormated, setInvestorGroupTokensFormated] = useState()
  const { investorGroupId } = useParams();
  const {
    getInvestorGroupTokens,
    investorGroupTokens,
    loadingInvestorGroupTokens,
    setPageTokensList,
    pageTokensList,
    totalPageTokensLists,
    selectedTokensRemove,
    setSelectedTokensRemove,
    removeTokens,
    removingTokens,
  } = useInvestorGroupsTokens();
  useEffect(() => {
    getInvestorGroupTokens(investorGroupId, pageTokensList);
  }, [pageTokensList, setPageTokensList]);

  useEffect(() => {
    if (investorGroupTokens) {
      const formatedTokens = investorGroupTokens.map((token) => {
        return {
          ...token,
          checkBox: <input
            type="checkbox"
            className="form-check-input"
            id="customCheckBox2"
            checked={selectedTokensRemove.includes(token.tokenId)}
            onClick={() => { handleChangeCheckBox(token) }}
          />
        };
      }
      );
      setInvestorGroupTokensFormated(formatedTokens);
    }
  }, [investorGroupTokens, selectedTokensRemove]);

  const handleChangeCheckBox = (
    token
  ) => {
    if (selectedTokensRemove.includes(token.tokenId)) {
      setSelectedTokensRemove(selectedTokensRemove.filter(item => item !== token.tokenId));
    } else {
      setSelectedTokensRemove([...selectedTokensRemove, token.tokenId])
    }
  }

  const handleChange = (event, value) => {
    setPageTokensList(value)
  };

  const handleVinculate = () => {
    setShowModalVinculationTokens(true)

  }
  const handleRemove = () => {
    removeTokens(investorGroupId, selectedTokensRemove)
  }

  if (loadingInvestorGroupTokens || removingTokens) {
    return <Loading />;
  }

  return (
    <div className="page-wrapper tokens-list">
      <div className="d-flex align-items-center pe-5 col-4">

        <Button variant="outlined" className={(selectedTokensRemove?.length || 0) ? "d-flex align-items-center " : "d-flex align-items-center disabled"} id="btn" onClick={(selectedTokensRemove?.length || 0) > 0 ? handleRemove : null}>
          <i className=" material-icons">close</i>
          <Translator path="pages.investorGroups.removeToken" />
        </Button>
        <Button className="d-flex align-items-center " onClick={handleVinculate}>
          <i className="material-icons">check</i>
          <Translator path="pages.investorGroups.vinculateToken" />
        </Button>
      </div>

      <div>

        <Table
          props={{
            tableData: investorGroupTokensFormated || [],
            tableColumns: COLUMNS,
            emptyImage: emptyInvestors,
          }}
        ></Table>

        <div className='pagination-div'>
          <Pagination
            count={totalPageTokensLists}
            page={pageTokensList}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      </div>

    </div>
  );
}
export default InvestorGroupTokenList;