import { createContext, useCallback, useContext, useState } from 'react';
import * as adminTokenProposalService from '../../services/Admin/adminTokenProposalService';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';

export const AdminTokenProposalContext = createContext({});

const AdminTokenProposalProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageLink, setImageLink] = useState('');
  const [imageBuffer, setImageBuffer] = useState('');
  const [allProposals, setAllProposals] = useState([]);
  const [tokenProposal, setAdminTokenProposal] = useState({});
  const [additionalFileBuffer, setAdditionalFileBuffer] = useState();


  const getAllProposals = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await adminTokenProposalService.getAllProposals();

      setAllProposals(data.proposals);
      return data.proposals;
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.title');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getAdminTokenProposal = useCallback(async (proposalId) => {
    setLoading(true);

    try {
      const { data } = await adminTokenProposalService.getAdminTokenProposal(proposalId);

      setAdminTokenProposal(data);
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.title');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);


  const sendAdminCounterTokenProposal = useCallback(async (proposal) => {
    setLoading(true);

    try {
      await adminTokenProposalService.sendAdminCounterTokenProposal(proposal);
      const successHeader = t('pages.tokenProposal.counterProposal.approveSwal.success');
      const successMessage = t('pages.tokenProposal.counterProposal.approveSwal.sucessMessage');
      swal(successHeader, successMessage, 'success');
      getAdminTokenProposal(proposal.tokenProposalId);
    } catch (error) {
      const errTitle = t('pages.tokenProposal.counterProposal.apiErr.title');
      const errBody = t('pages.tokenProposal.counterProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);


  const downloadTokenImage = useCallback(async (id = '') => {
    setLoadingImage(true);

    try {
      const newId = id.replaceAll('/', '%2F');

      const { data } = await adminTokenProposalService.downloadTokenImage(newId);

      setImageBuffer(data);
      return data;
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.download');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingImage(false);
    }
  }, []);


  const downloadAdditionalDoc = useCallback(async (link) => {
    try {
      const { data } = await adminTokenProposalService.downloadAdditionalDoc(link);

      setAdditionalFileBuffer(data);
      return data;
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.docUpload');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    }
  }, []);

  const uploadTokenImage = useCallback(
    async (file) => {
      setLoadingImage(true);

      try {
        const { data } = await adminTokenProposalService.uploadTokenImage(file);

        setImageLink(data);
      } catch (error) {
        const errTitle = t('pages.tokenProposal.upload.apiErr.title');
        const errBody = t('pages.tokenProposal.upload.apiErr.body');
        swal(errTitle, errBody, 'error');
      } finally {
        setLoadingImage(false);
      }
    },
    [],
  );

  const approveTokenProposal = useCallback(
    async (tokenProposalId) => {
      setLoading(true);

      try {
        await adminTokenProposalService.approveProposal(tokenProposalId);
        const successHeader = t('pages.tokenProposal.approveProposal.approveSwal.success');
        const successMessage = t('pages.tokenProposal.approveProposal.approveSwal.sucessMessage');
        swal(successHeader, successMessage, 'success');
        getAdminTokenProposal(tokenProposalId);

      } catch (error) {
        const errTitle = t('pages.tokenProposal.approveProposal.apiErr.title');
        const errBody = t('pages.tokenProposal.approveProposal.apiErr.body');
        swal(errTitle, errBody, 'error');
      } finally {
        setLoading(false);
      }
    },
    [setImageLink],
  );

  return (
    <AdminTokenProposalContext.Provider
      value={{
        loading,
        imageLink,
        downloadTokenImage,
        imageBuffer,
        getAllProposals,
        allProposals,
        loadingImage,
        getAdminTokenProposal,
        tokenProposal,
        downloadAdditionalDoc,
        additionalFileBuffer,
        sendAdminCounterTokenProposal,
        uploadTokenImage,
        approveTokenProposal,
      }}
    >
      {children}
    </AdminTokenProposalContext.Provider>
  );
};

const useAdminTokenProposal = () => {
  return useContext(AdminTokenProposalContext);
};

export { AdminTokenProposalProvider, useAdminTokenProposal };
