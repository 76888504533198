import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'pages.investorGroups.name'} />,
    Footer: <Translator path={'pages.investorGroups.name'} />,
    accessor: 'name',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.description'} />,
    Footer: <Translator path={'pages.investorGroups.description'} />,
    accessor: 'description',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investorGroups.action'} />,
    Footer: <Translator path={'pages.investorGroups.action'} />,
    accessor: 'action',
    isSortable: false,
  },
];
