import { Col } from 'react-bootstrap';
import { useEffect } from 'react';

const Dashboard = () => {

  useEffect(() => {
   
  }, []);
    return (
      <Col className="page-wrapper">
     
      </Col>
    );
};

export default Dashboard;
