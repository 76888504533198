import * as api from '../api';

export function getAllIssuers(params) {
  return api.get('issuers/', params);
}


export function getIssuer(userId) {
  return api.get(`issuers/${userId}`);
}

export function approveIssuer(userId) {
  return api.post(`issuers/admin/${userId}`);
}
