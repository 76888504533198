import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'pages.investors.name'} />,
    Footer: <Translator path={'pages.investors.name'} />,
    accessor: 'physicalUser.name',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investors.email'} />,
    Footer: <Translator path={'pages.investors.email'} />,
    accessor: 'physicalUser.email',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investors.status'} />,
    Footer: <Translator path={'pages.investors.status'} />,
    accessor: 'statusFormated',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.investors.action'} />,
    Footer: <Translator path={'pages.investors.action'} />,
    accessor: 'action',
    isSortable: false,
  },


];
