import * as api from '../api';

export function uploadTokenImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('tokenProposal/image', formData);
}

export function downloadTokenImage(imageId) {
  return api.get(`upload/download/${imageId}`);
}

export function sendAdminCounterTokenProposal(body) {
  return api.patch(`tokenProposal/admin/${body.tokenProposalId}`, body);
}

export function approveProposal(tokenProposalId) {
  return api.post(`tokenProposal/admin/approveProposal/${tokenProposalId}`,);
}


export function getAllProposals(params) {
  return api.get('tokenProposal', params);
}

export function uploadAdditionalDoc(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('upload/images', formData);
}

export function getAdminTokenProposal(proposalId) {
  return api.get(`tokenProposal/${proposalId}`);
}

export function updateTokenProposal(proposalId, body) {
  return api.patch(`tokenProposal/issuer/update/${proposalId}`, body);
}

export function downloadAdditionalDoc(link) {
  link = link.replace('/', '%2F');
  return api.get(`tokenProposal/image/download/${link}`);
}

export function preApproveProposal(proposalId) {
  return api.patch(`tokenProposal/issuer/preApproveProposal/${proposalId}`);
}

