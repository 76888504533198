import * as api from '../api';

export function getAllInvestorGroups(params) {
  return api.get('investor-groups/', params);
}


export function getInvestorGroup(investorGroupId) {
  return api.get(`investor-groups/${investorGroupId}`);
}

export function getInvestorGroupInvestors(investorGroupId, params) {
  return api.get(`investor-groups/${investorGroupId}/investors`, params);
}

export function getInvestorsToVinculate(investorGroupId, params) {
  return api.get(`investor-groups/${investorGroupId}/investors-vinculate`, params);
}

export function addInvestorsToInvestorGroup(investorGroupId, investors) {
  return api.post(`investor-groups/${investorGroupId}/add-investors`, { investorsIds: investors });
}

export function removeInvestorsFromInvestorGroup(investorGroupId, investors) {
  return api.post(`investor-groups/${investorGroupId}/remove-investors`, { investorsIds: investors });
}


export function getInvestorGroupTokens(investorGroupId, params) {
  return api.get(`investor-groups/${investorGroupId}/tokens`, params);
}

export function getTokensToVinculate(investorGroupId, params) {
  return api.get(`investor-groups/${investorGroupId}/tokens-vinculate`, params);
}

export function addTokensToInvestorGroup(investorGroupId, tokens) {
  return api.post(`investor-groups/${investorGroupId}/add-tokens`, { tokensIds: tokens });
}

export function removeTokensFromInvestorGroup(investorGroupId, tokens) {
  return api.post(`investor-groups/${investorGroupId}/remove-tokens`, { tokensIds: tokens });
}
