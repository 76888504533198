import { createContext, useCallback, useContext, useState } from 'react';
import * as investorGroupsService from '../../services/Admin/investorGroupsService';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Translator } from '@components';
import { useNavigate } from 'react-router-dom';

export const InvestorGroupsContext = createContext({});

const InvestorGroupsProvider = ({ children }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingInvestorGroup, setLoadingInvestorGroup] = useState(false);
  const [investorGroups, setInvestorGroups] = useState();
  const [investorGroup, setInvestorGroup] = useState();
  const [page, setPage] = useState();
  const [totalPages, setTotalPages] = useState(0);

  const [showModalVinculationInvestor, setShowModalVinculationInvestor] = useState(false);
  const [showModalVinculationTokens, setShowModalVinculationTokens] = useState(false);

  const getInvestorGroups = useCallback(async (page) => {
    setLoading(true);

    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 12,
      };

      const { data } = await investorGroupsService.getAllInvestorGroups(params);
      setTotalPages(Math.ceil(data.total / params.pageSize));
      const formatedInvestorGroups = data.data.map((investorGroup) => {
        return {
          ...investorGroup,
          action: (
            <Button
              className="text-nowrap w-auto"
              variant="outline-primary"
              size="lg"
              id="btn-outlined"
              onClick={
                () => {
                  navigate(`/investorGroups/${investorGroup.investorGroupId}`);
                }
              }
            >
              <Translator path={'pages.investorGroups.details'} />
            </Button>
          )
        };
      }
      );
      setInvestorGroups(formatedInvestorGroups);
    } catch (error) {
      const errTitle = t('pages.investorGroups.apiErr.title');
      const errBody = t('pages.investorGroups.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getInvestorGroupDetail = useCallback(async (investorGroupId) => {
    setLoadingInvestorGroup(true);
    try {

      const data = await investorGroupsService.getInvestorGroup(investorGroupId);
      setInvestorGroup(data.data);
    } catch (error) {
      const errTitle = t('pages.investorGroups.apiErr.title');
      const errBody = t('pages.investorGroups.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingInvestorGroup(false);
    }
  }, []);



  return (
    <InvestorGroupsContext.Provider
      value={{
        loading,
        investorGroups,
        getInvestorGroups,
        investorGroup,
        setInvestorGroups,
        totalPages,
        page,
        setPage,
        getInvestorGroupDetail,
        showModalVinculationInvestor,
        setShowModalVinculationInvestor,
        loadingInvestorGroup,
        showModalVinculationTokens,
        setShowModalVinculationTokens,

      }}
    >
      {children}
    </InvestorGroupsContext.Provider>
  );
};

const useInvestorGroups = () => {
  return useContext(InvestorGroupsContext);
};

export { InvestorGroupsProvider, useInvestorGroups };
