import { emptyInvestors } from '@assets';
import { Loading, Table, TokenDetails, Translator } from '@components';
import { useInvestorGroups } from '@contexts-admin';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { COLUMNS } from './TableColumns';
import Pagination from '@mui/material/Pagination';

export const InvestorGroups = () => {
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const { getInvestorGroups, loading, investorGroups, page, setPage, totalPages } = useInvestorGroups();
  const navigate = useNavigate();

  useEffect(() => {
    getInvestorGroups(page);
  }, [page, setPage]);


  const handleChange = (event, value) => {
    setPage(value)
  };


  if (loading) {
    return <Loading />;
  }

  if (investorGroups?.length > 0)
    return (
      <div className="page-wrapper tokens-list">
        <div className="d-flex title">
          <p>
            <Translator path={'pages.investorGroups.title'} />
          </p>
          {/* // todo: Adicionar filtros
           */}

        </div>
        <Table
          props={{
            tableData: investorGroups,
            tableColumns: COLUMNS,
            emptyImage: emptyInvestors,
          }}
        ></Table>

        <div className='pagination-div'>
          <Pagination
            count={totalPages}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>

      </div>
    );
};

export default InvestorGroups;
