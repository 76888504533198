import React, { useEffect, useState } from 'react';
import { noImageSvg } from '@assets';
import * as adminService from '../../../../../services/Admin/adminService';
import { Buffer } from 'buffer';

const detectFileType = (url) => {
    const images = ['png', 'jpg', 'jpeg', 'gif'];
    const pdfKeywords = ['pdf'];

    const lowercasedUrl = url.toLowerCase();

    if (images.some((ext) => lowercasedUrl.includes(ext))) {
        return 'image';
    } else if (pdfKeywords.some((keyword) => lowercasedUrl.includes(keyword))) {
        return 'pdf';
    } else {
        return 'unsupported';
    }
};

const DocumentView = ({ url, title }) => {
    const [preview, setPreview] = useState();
    const [loading, setLoading] = useState();
    const [fileType, setFileType] = useState('unknown');

    useEffect(() => {
        const download = async () => {
            setLoading(true);
            try {
                const newId = url.replaceAll('/', '%2F');
                const { data } = await adminService.downloadImage(newId);

                if (data) {
                    const type = detectFileType(url);

                    if (type === 'image') {
                        const downloadedImage = Buffer.from(data, 'binary').toString('base64');
                        setPreview(downloadedImage);
                        setFileType('image');

                    } else if (type === 'pdf') {
                        setFileType('pdf');
                        const pdfBase64 = Buffer.from(data, 'binary').toString('base64');
                        setPreview(pdfBase64);
                    } else {
                        console.error('Unsupported file type');
                        setFileType('unsupported');
                    }
                }
            } catch (error) {
                console.error(error);
                setFileType('error');
            } finally {
                setLoading(false);
            }
        };

        if (url) download();
    }, [url]);

    return (
        <>
            <div className="col-6 image-container img-responsive" style={{ justifyContent: 'flex-start', padding: 10, margin: 0 }}>
                <div className="d-flex-12">
                    {title ? <h5 className="font-w400 mb-0">{title}</h5> : ''}
                </div>
                <div className="d-flex-6">
                    {loading ? (
                        <div className="d-flex-6 loading-spinner" style={{ position: 'center' }} />
                    ) : fileType === 'pdf' ? (
                        <iframe
                            title="PDF Preview"
                            width="100%"
                            height="500px"
                            src={`data:application/pdf;base64,${preview}`}
                        />
                    ) : (
                        <img
                            className="img-responsive img-fluid"
                            src={preview ? `data:image/jpeg;base64,${preview}` : noImageSvg}
                            alt=""
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default DocumentView;
