import axios from 'axios';
import * as api from '../api';

export function registerIssuer(body) {
  return api.post('register', body);
}

export function resetToken(issuerLoginId) {
  return api.post(`accessToken/${issuerLoginId}`);
}

export function sendTokenEmail(email) {
  return api.get(`register/resetPassword/${email}`);
}

export function validateCode(params) {
  return api.get('accessToken/validateToken', params);
}

export function setPassword(body) {
  return api.post('register/password', body);
}

export function resetPassword(body) {
  return api.put('register/password', body);
}


export function loginAdmin(body) {
  return axios.post(
    `${process.env.REACT_APP_KEYCLOAK_URL}/realms/criptech-admin/protocol/openid-connect/token`,
    body,
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    },
  );
}
