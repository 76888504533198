import React from 'react';
import ReactDOM from 'react-dom/client';
import Investor from './Investor';
import Issuer from './Issuer';
import Admin from './Admin';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak, keycloakProviderInitConfig } from './utils/keycloak-auth';
import ThemeContext from '@contexts/ThemeContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CdiProvider } from './contexts/CdiContext';
import {
  ProfileProvider,
  TokenProvider,
  PurchaseProvider,
  DepositProvider,
  WithdrawProvider,

} from '@contexts-investor';
import {
  IssuerProvider,
  RegisterProvider,
  TokenProposalProvider,
  WalletProvider,
  BankProvider,
  TokenProvider as IssuerTokenProvider,

} from '@contexts-issuer';

import {
  RegisterProviderAdmin,
  InvestorsProvider,
  AdminProvider,
  AdminIssuersProvider,
  AdminTokenProposalProvider,
  InvestorGroupsProvider,
  InvestorGroupsInvestorProvider,
  InvestorGroupsTokensProvider,
} from '@contexts-admin';
import './translations/index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      scriptProps={{ async: false, defer: false, appendTo: 'head', nonce: undefined }}
    >
      {process.env.REACT_APP_BUILD_TARGET === 'investor' ? (
        <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
          <Provider store={store}>
            <BrowserRouter>
              <ThemeContext>
                <CdiProvider>
                  <ProfileProvider>
                    <TokenProvider>
                      <PurchaseProvider>
                        <DepositProvider>
                          <WithdrawProvider>
                            <Investor />
                          </WithdrawProvider>
                        </DepositProvider>
                      </PurchaseProvider>
                    </TokenProvider>
                  </ProfileProvider>
                </CdiProvider>
              </ThemeContext>
            </BrowserRouter>
          </Provider>
        </ReactKeycloakProvider>
      ) : // emissor
        process.env.REACT_APP_BUILD_TARGET === 'issuer' ? (
          <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
            <Provider store={store}>
              <BrowserRouter>
                <ThemeContext>
                  <CdiProvider>
                    <RegisterProvider>
                      <IssuerProvider>
                        <BankProvider>
                          <TokenProposalProvider>
                            <IssuerTokenProvider>
                              <WalletProvider>
                                <Issuer />
                              </WalletProvider>
                            </IssuerTokenProvider>
                          </TokenProposalProvider>
                        </BankProvider>
                      </IssuerProvider>
                    </RegisterProvider>
                  </CdiProvider>
                </ThemeContext>
              </BrowserRouter>
            </Provider>
          </ReactKeycloakProvider>
        ) : //parceiro
          process.env.REACT_APP_BUILD_TARGET === 'partner' ? (
            ''
          ) : //g4admin
            process.env.REACT_APP_BUILD_TARGET === 'admin' ? (
              <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
                <Provider store={store}>
                  <BrowserRouter>
                    <ThemeContext>
                      <AdminProvider>
                        <AdminIssuersProvider>
                          <AdminTokenProposalProvider>
                            <InvestorGroupsProvider>
                              <InvestorGroupsInvestorProvider>
                                <InvestorGroupsTokensProvider>
                                  <RegisterProviderAdmin>
                                    <InvestorsProvider>
                                      <Admin />
                                    </InvestorsProvider>
                                  </RegisterProviderAdmin>
                                </InvestorGroupsTokensProvider>
                              </InvestorGroupsInvestorProvider>
                            </InvestorGroupsProvider>
                          </AdminTokenProposalProvider>

                        </AdminIssuersProvider>
                      </AdminProvider>
                    </ThemeContext>
                  </BrowserRouter>
                </Provider>
              </ReactKeycloakProvider>
            ) : (
              ''
            )}
    </GoogleReCaptchaProvider>
    ,
  </>,
);
