import { emptyInvestors } from '@assets';

import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useInvestorGroups, useInvestorGroupsInvestors } from '@contexts-admin';
import { Translator, Table, Loading } from '@components';
import { COLUMNS } from './TableColumns';
import Pagination from '@mui/material/Pagination'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const VinculateInvestorModal = () => {
  const { showModalVinculationInvestor, setShowModalVinculationInvestor } = useInvestorGroups();
  const { investorGroupId } = useParams();
  const { t } = useTranslation();
  const [formatedInvestorsToVinculate, setFormatedInvestorsToVinculate] = useState();

  const {
    getInvestorsToVinculate,
    investorsToVinculate,
    loadingInvestorsToVinculate,
    setPageInvestorsToVinculate,
    pageInvestorsToVinculate,
    totalPageInvestorsToVinculate,
    vinculatingInvestors,
    vinculateInvestors,
    selectedUsers,
    setSelectedUser,

  } = useInvestorGroupsInvestors();
  useEffect(() => {
    if (showModalVinculationInvestor) {

      getInvestorsToVinculate(investorGroupId, pageInvestorsToVinculate);
    }
  }, [pageInvestorsToVinculate, setPageInvestorsToVinculate, showModalVinculationInvestor]);



  const handleChangeCheckBox = (
    investor
  ) => {
    if (selectedUsers.includes(investor.userId)) {
      setSelectedUser(selectedUsers.filter(item => item !== investor.userId));
    } else {
      setSelectedUser([...selectedUsers, investor.userId])
    }
  }

  const handleVinculate = () => {
    vinculateInvestors(investorGroupId, selectedUsers)
  }

  useEffect(
    () => {
      if (investorsToVinculate) {
        const formatedInvestors = investorsToVinculate.map((investor) => {
          return {
            ...investor,
            typeFormated: t(`types.investorTypes.${investor.type.replace(/ /g, '')}`),
            name: investor?.legalUser ? investor?.legalUser?.corporateName : investor?.physicalUser?.name,
            cpfCnpj: investor?.legalUser ? investor?.legalUser?.cnpj : investor?.physicalUser?.cpf,
            checkBox: <input
              type="checkbox"
              className="form-check-input"
              id="customCheckBox2"
              checked={selectedUsers.includes(investor.userId)}
              onClick={() => { handleChangeCheckBox(investor) }}
            />
          };
        }
        );
        setFormatedInvestorsToVinculate(formatedInvestors)
      }


    }, [investorsToVinculate, selectedUsers]);

  const handleChange = (event, value) => {
    setPageInvestorsToVinculate(value)
  };


  return (
    <Modal
      show={showModalVinculationInvestor}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
    // onHide={getToken(token?.tokenId)}
    >
      <Modal.Body style={{ padding: 0 }}>
        <div className="buy-modal-title text-center">
          <Translator path="pages.investorGroups.vinculateInvestor" />
        </div>
        {
          loadingInvestorsToVinculate || vinculatingInvestors ?
            <Loading /> :
            <div>
              <Table
                props={{
                  tableData: formatedInvestorsToVinculate || [],
                  tableColumns: COLUMNS,
                  emptyImage: emptyInvestors,
                }}
              />
              <div className='pagination-div'>
                <Pagination
                  count={totalPageInvestorsToVinculate}
                  page={pageInvestorsToVinculate}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
        }
        <div className="buy-modal-actions actions d-flex flex-column justify-content-center">
          <Button
            variant="primary"
            size="lg"
            className={(selectedUsers?.length || 0) ? "text-center" : "text-center disabled"}
            id="btn"
            onClick={(selectedUsers?.length || 0) > 0 ? handleVinculate : null}>
            <Translator path={'pages.investorGroups.vinculateInvestors'} />
          </Button>
          <Button
            variant="outline-primary"
            size="lg"
            id="btn-outlined"
            className="m-0"
            onClick={() => setShowModalVinculationInvestor(false)}
          >
            <Translator path={'pages.investorGroups.cancel'} />
          </Button>
        </div>
      </Modal.Body>
    </Modal >
  );
};

export default VinculateInvestorModal;
