import * as api from '../api';

export function getAllInvestors(params) {
  return api.get('users/', params);
}


export function getInvestor(userId) {
  return api.get(`users/${userId}`);
}

export function approveInvestor(userId) {
  return api.get(`users/approve-user/${userId}`);
}
