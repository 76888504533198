
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import { noImageSvg } from '@assets';
import * as adminService from '../../../services/Admin/adminService';


const UserImagePreview = ({ url }) => {
    const [preview, setPreview] = useState();
    const [loading, setLoading] = useState();
    useEffect(() => {
        const download = async () => {
            setLoading(true);
            try {
                const newId = url.replaceAll('/', '%2F');
                const { data } = await adminService.downloadImage(newId);
                if (data) {
                    const downloadedImage = Buffer.from(data.data, 'binary').toString('base64');
                    setPreview(downloadedImage);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        if (url) download();
    }, []);


    return (
        <>
            <div className="d-block">
                {loading ?
                    <div
                        className=" d-flex-6 loading-spinner"
                        style={{
                            position: 'center',
                        }}
                    /> :
                    <img src={preview ? `data:image/jpeg;base64,${preview}` : noImageSvg} className="avatar avatar-xxl" alt="" />
                }
            </div>
        </>
    )
};


export default UserImagePreview;