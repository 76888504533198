import { emptyInvestors } from '@assets';

import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useInvestorGroups, useInvestorGroupsTokens } from '@contexts-admin';
import { Translator, Table, Loading } from '@components';
import { COLUMNS } from './TableColumns';
import Pagination from '@mui/material/Pagination'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const VinculateTokenModal = () => {
  const { showModalVinculationTokens, setShowModalVinculationTokens } = useInvestorGroups();
  const { investorGroupId } = useParams();
  const { t } = useTranslation();
  const [formatedTokensToVinculate, setFormatedTokensToVinculate] = useState();

  const {
    getTokensToVinculate,
    tokensToVinculate,
    loadingTokensToVinculate,
    setPageTokensToVinculate,
    pageTokensToVinculate,
    totalPageTokensToVinculate,
    vinculatingTokens,
    vinculateTokens,
    selectedTokens,
    setSelectedTokens,

  } = useInvestorGroupsTokens();
  useEffect(() => {
    if (showModalVinculationTokens) {

      getTokensToVinculate(investorGroupId, pageTokensToVinculate);
    }
  }, [pageTokensToVinculate, setPageTokensToVinculate, showModalVinculationTokens]);



  const handleChangeCheckBox = (
    token
  ) => {
    if (selectedTokens.includes(token.userId)) {
      setSelectedTokens(selectedTokens.filter(item => item !== token.tokenId));
    } else {
      setSelectedTokens([...selectedTokens, token.tokenId])
    }
  }

  const handleVinculate = () => {
    vinculateTokens(investorGroupId, selectedTokens)
  }

  useEffect(
    () => {
      if (tokensToVinculate) {
        const formatedTokens = tokensToVinculate.map((token) => {
          return {
            ...token,
            checkBox: <input
              type="checkbox"
              className="form-check-input"
              id="customCheckBox2"
              checked={selectedTokens.includes(token.tokenId)}
              onClick={() => { handleChangeCheckBox(token) }}
            />
          };
        }
        );
        setFormatedTokensToVinculate(formatedTokens)
      }


    }, [tokensToVinculate, selectedTokens]);

  const handleChange = (event, value) => {
    setPageTokensToVinculate(value)
  };


  return (
    <Modal
      show={showModalVinculationTokens}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
    // onHide={getToken(token?.tokenId)}
    >
      <Modal.Body style={{ padding: 0 }}>
        <div className="buy-modal-title text-center">
          <Translator path="pages.investorGroups.vinculateToken" />
        </div>
        {
          loadingTokensToVinculate || vinculatingTokens ?
            <Loading /> :
            <div>
              <Table
                props={{
                  tableData: formatedTokensToVinculate || [],
                  tableColumns: COLUMNS,
                  emptyImage: emptyInvestors,
                }}
              />
              <div className='pagination-div'>
                <Pagination
                  count={totalPageTokensToVinculate}
                  page={pageTokensToVinculate}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
        }
        <div className="buy-modal-actions actions d-flex flex-column justify-content-center">
          <Button
            variant="primary"
            size="lg"
            className={(selectedTokens?.length || 0) ? "text-center" : "text-center disabled"}
            id="btn"
            onClick={(selectedTokens?.length || 0) > 0 ? handleVinculate : null}>
            <Translator path={'pages.investorGroups.vinculateTokens'} />
          </Button>
          <Button
            variant="outline-primary"
            size="lg"
            id="btn-outlined"
            className="m-0"
            onClick={() => setShowModalVinculationTokens(false)}
          >
            <Translator path={'pages.investorGroups.cancel'} />
          </Button>
        </div>
      </Modal.Body>
    </Modal >
  );
};

export default VinculateTokenModal;
