import { createContext, useCallback, useContext, useState } from 'react';
import * as investorGroupsService from '../../services/Admin/investorGroupsService';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';


export const InvestorGroupsTokensContext = createContext({});

const InvestorGroupsTokensProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loadingInvestorGroupTokens, setLoadingInvestorGroupTokens] = useState(false);
  const [loadingTokensToVinculate, setLoadingTokensToVinculate] = useState(false);
  const [vinculatingTokens, setVinculatingTokens] = useState(false);
  const [removingTokens, setRemovingTokens] = useState(false);
  const [tokensToVinculate, setTokensToVinculate] = useState();
  const [pageTokensToVinculate, setPageTokensToVinculate] = useState();
  const [pageTokensList, setPageTokensList] = useState();
  const [totalPageTokensToVinculate, setTotalPageTokensToVinculate] = useState(0);
  const [totalPageTokensLists, setTotalPageTokensLists] = useState(0);
  const [investorGroupTokens, setInvestorGroupTokens] = useState();
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [selectedTokensRemove, setSelectedTokensRemove] = useState([]);


  const getInvestorGroupTokens = useCallback(async (investorGroupId, page) => {
    setLoadingInvestorGroupTokens(true);
    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 12,
      };
      const { data } = await investorGroupsService.getInvestorGroupTokens(investorGroupId, params);
      setTotalPageTokensLists(Math.ceil(data.total / params.pageSize));

      setInvestorGroupTokens(data.data);
    } catch (error) {
      const errTitle = t('pages.investorGroups.investorsErr.title');
      const errBody = t('pages.investorGroups.investorsErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingInvestorGroupTokens(false);
    }
  }, []);

  const getTokensToVinculate = useCallback(async (investorGroupId, page) => {
    setLoadingTokensToVinculate(true);
    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 8,
      };
      const { data } = await investorGroupsService.getTokensToVinculate(investorGroupId, params);
      setTotalPageTokensToVinculate(Math.ceil(data.total / params.pageSize));

      setTokensToVinculate(data.data);
    } catch (error) {
      const errTitle = t('pages.investorGroups.investorsVinculateErr.title');
      const errBody = t('pages.investorGroups.investorsVinculateErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingTokensToVinculate(false);
    }
  }, []);


  const vinculateTokens = useCallback(async (investorGroupId, tokens) => {
    setVinculatingTokens(true);
    try {
      const { data } = await investorGroupsService.addTokensToInvestorGroup(investorGroupId, tokens);
      const successHeader = t('pages.investorGroups.vinculateTokenSwal.success');
      const successMessage = t('pages.investorGroups.vinculateTokenSwal.sucessMessage');
      swal(successHeader, successMessage, 'success');
      getTokensToVinculate(investorGroupId);
      getInvestorGroupTokens(investorGroupId);
      setSelectedTokens([])
    } catch (error) {
      const errTitle = t('pages.investorGroups.vinculateTokenSwal.fail');
      const errBody = t('pages.investorGroups.vinculateTokenSwal.failMessage');
      swal(errTitle, errBody, 'error');
    } finally {
      setVinculatingTokens(false);
    }
  }, []);

  const removeTokens = useCallback(async (investorGroupId, tokens) => {
    setRemovingTokens(true);
    try {
      const { data } = await investorGroupsService.removeTokensFromInvestorGroup(investorGroupId, tokens);
      const successHeader = t('pages.investorGroups.removeSwal.success');
      const successMessage = t('pages.investorGroups.removeSwal.sucessMessage');
      swal(successHeader, successMessage, 'success');
      getInvestorGroupTokens(investorGroupId);
      setSelectedTokensRemove([])
    } catch (error) {
      const errTitle = t('pages.investorGroups.removeSwal.fail');
      const errBody = t('pages.investorGroups.removeSwal.failMessage');
      swal(errTitle, errBody, 'error');
    } finally {
      setRemovingTokens(false);
    }
  }, []);


  return (
    <InvestorGroupsTokensContext.Provider
      value={{

        setPageTokensList,
        pageTokensList,
        getTokensToVinculate,
        tokensToVinculate,
        setTokensToVinculate,
        loadingTokensToVinculate,
        setPageTokensToVinculate,
        pageTokensToVinculate,
        totalPageTokensToVinculate,
        getTokensToVinculate,
        vinculatingTokens,
        vinculateTokens,
        selectedTokens,
        setSelectedTokens,
        selectedTokensRemove,
        setSelectedTokensRemove,
        removeTokens,
        removingTokens,
        setRemovingTokens,
        loadingInvestorGroupTokens,
        totalPageTokensLists,
        investorGroupTokens,
        getInvestorGroupTokens,
      }}
    >
      {children}
    </InvestorGroupsTokensContext.Provider>
  );
};

const useInvestorGroupsTokens = () => {
  return useContext(InvestorGroupsTokensContext);
};

export { InvestorGroupsTokensProvider, useInvestorGroupsTokens };
