import { emptyProposals } from '@assets';
import { Loading, Table, TokenDetails, Translator, StatusCard } from '@components';
import { useIssuer, useToken, useTokenProposal } from '@contexts-issuer';
import { formatDate } from 'date-utils-2020';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { COLUMNS } from './TableColumns';

export const ProposalList = () => {
  const [proposals, setProposals] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [proposalToDetail, setProposalToDetail] = useState({});

  const { getAllProposals, allProposals, loading, setWasProposalUpdated } = useTokenProposal();
  const { getAllTokens } = useToken();
  const { issuerProfile } = useIssuer();

  const navigate = useNavigate();

  useEffect(() => {
    const issuerId = localStorage.getItem('issuerId');
    getAllProposals({ issuerId });
    getAllTokens({ issuerId });
    setWasProposalUpdated(false);
  }, []);

  useEffect(() => {
    if (allProposals) {
      const proposals = allProposals.map((proposal) => {
        const actions = (
          <Dropdown style={{ color: 'var(--orange)' }}>
            <Dropdown.Toggle as="div" className="i-false sidebar-select">
              <i className="material-icons cursor-pointer">more_horiz</i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {proposal.status === 'IN_ANALYSIS' ? (
                <Dropdown.Item onClick={() => navigate(`/tokenProposal/edit/${proposal.tokenProposalId}`)}>
                  <Translator path={'components.proposalsList.action.edit'} />
                </Dropdown.Item>
              ) : proposal.status === 'NEGOTIATING' ? (
                <Dropdown.Item onClick={() => navigate(`/tokenProposal/counterProposal/${proposal.tokenProposalId}`)}>
                  <Translator path={'components.proposalsList.action.view'} />
                </Dropdown.Item>
              ) : proposal.status === 'ACCEPTED' ? (
                <Dropdown.Item onClick={() => handleShowDetailsModal(proposal)}>
                  <Translator path={'components.proposalsList.action.detail'} />
                </Dropdown.Item>
              ) : proposal.status === 'PRE_ACCEPTED' ? (
                <Dropdown.Item onClick={() => handleShowDetailsModal(proposal)}>
                  <Translator path={'components.proposalsList.action.detail'} />
                </Dropdown.Item>
              ) : (
                ''
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
        return {
          ...proposal,
          formattedInvoiceValue: `${proposal.invoiceValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}`,
          formattedValueToReceive: `${proposal.valueToReceive.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}`,
          formattedFundingDate: formatDate(proposal.fundingLimitDate, 'dd/MM/yyyy'),
          formattedLiquidationDate: formatDate(proposal.liquidationDate, 'dd/MM/yyyy'),
          formattedStatus: <StatusCard status={proposal.status} />,
          actions: proposal.status === 'REJECTED' || proposal.status === 'ANNULLED' ? '' : actions,
        };
      });

      setProposals(proposals);
    }
  }, [allProposals]);

  const handleShowDetailsModal = async (token) => {
    setProposalToDetail(token);
    setShowDetailsModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  if (allProposals) {
    return (
      <div className="page-wrapper">
        <Table
          props={{
            tableData: proposals,
            tableColumns: COLUMNS,
            emptyImage: emptyProposals,
          }}
        ></Table>
        <TokenDetails
          show={showDetailsModal}
          setState={setShowDetailsModal}
          tokenOrProposal={proposalToDetail}
          issuer={issuerProfile}
          isProposal={true}
        />
      </div>
    );
  }
};
