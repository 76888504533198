export default {
  investor: {
    apiErrors: {
      error: 'Erro',
      query: 'Falha na busca de dados',
      userNotFound: 'Usuário não encontrado',
      tokenNotFound: 'Token não encontrado',
      entityNotFound: 'Token ou usuário não encontrado',
      upload: 'Falha ao fazer upload',
      tokenGen: 'Falha ao gerar token',
      tokenExpire: 'Token de autenticação expirado, faça login novamente!',
      emailSending: 'Falha ao enviar email',
      keycloakCon: 'Falha na conexão com o keycloak',
      quantity: 'Quantidade de tokens requerida não disponivel',
      blockChainWallet: 'Falha ao criar carteira do usuário na blockchain',
      badRequest: 'Falha ao enviar requisição',
      tokenList: 'Falha ao buscar tokens',
      tokenDetails: 'Falha ao buscar detalhes do token',
      profile: 'Falha ao buscar dados do usuário',
      highlightedTokens: 'Falha ao buscar tokens em destaque',
      deposit: 'Falha ao buscar os depósitos do usuário',
      recaptcha: 'Recaptcha inválido'
    },
    loading: 'Carregando...',
    success: 'Sucesso',
    sucessMessage: 'A operação foi bem sucedida',
    dashboard: {
      firstSteps: 'Primeiros passos',
      stepsCard: {
        deposit: 'Depósito',
        depositMsg: 'Faça o seu primeiro depósito em nossa plataforma.',
        waiting1: 'Por favor, conclua o “Passo 1” para liberar o “Passo 2”.',
        investment: 'Investimento',
        investmentMsg: 'Realize seu primeiro investimento e comece a aumentar sua renda.',
        waiting2: 'Por favor, conclua os passos anteriores liberar este passo.',
        withdraw: 'Saque',
        withdrawMsg: 'Efetue seu primeiro saque em nossa plataforma.',
        depositBtn: 'Depositar',
        complete: 'Concluído',
        waitingBtn: 'Aguardando...',
        investmentBtn: 'Investir',
        withdrawBtn: 'Sacar',
        step1: 'Passo 1 de 3',
        step2: 'Passo 2 de 3',
        step3: 'Passo 3 de 3',
      },
      currentBalance: 'Saldo atual',
      totalAssets: 'Patrimônio total',
      totalAssetsInfo: 'Patrimônio é o seu saldo atual + seus investimentos',
      highlightedTokens: 'Tokens em destaque',
    },
    login: {
      header: 'Entrar',
      title: 'Informações pessoais',
      body: 'Digite o seu e-mail e sua senha',
      email: 'E-mail',
      password: 'Senha',
      submit: 'Entrar',
      forgot: 'Esqueceu a senha?',
      keep: 'Manter conectado',
      link: ' Clique aqui!',
      signup: 'Não possui uma conta?',
      create: 'Criar conta',
      login: 'Fazer login',
      selectaccount: 'Selecione a conta!',
      selectaccounttext: 'Por favor, escolha uma das opções abaixo para prosseguir.',
      continueas: 'Continuar como: ',
      concluded: 'Cadastro concluído!',
      concludedtext: 'Agora é possível efetuar o login utilizando as mesmas informações da sua conta física. Em seguida, você poderá selecionar o tipo de login desejado: pessoa física ou jurídica.',
      errors: {
        email: 'Por favor insira seu email!',
        password: 'Por favor insira sua senha!',
        title: 'Credenciais inválidas',
        credentials: 'Por favor, verifique seu email e senha.',
      },
    },
    register: {
      header: 'Cadastrar',
      title: 'Criar conta',
      body: 'Digite seus dados pessoais abaixo',
      bodypj: 'Digite os dados da empresa abaixo',
      corporatename: 'Razão social',
      foundationdate: 'Data de fundação',
      socialcontractandbylaws: 'Contrato social, Estatuto',
      document: 'Adicionar outro documento',
      declare: 'Eu declaro que a empresa: ',
      firstcheckbox: 'Compreenda os conceitos de Prevenção à Lavagem de Dinheiro (PLD), terrorismo e corrupção, e é importante ressaltar que nenhuma entidade ligada à Empresa está envolvida em qualquer uma dessas práticas.',
      secondcheckbox: 'Possui as políticas e manuais necessários para implementar o KYC (Know Your Customer) de acordo com a lei, comprometendo a seguir as orientações da Criptech.',
      thirdcheckbox: 'Concorde com os ',
      termsofuse: 'termos de uso e política de privacidade',
      name: 'Nome completo',
      email: 'E-mail',
      occupation: 'Profissão',
      wage: 'Renda mensal',
      singup: 'Cadastre-se',
      select: 'Por favor, selecione uma opção:',
      userpf: 'Pessoa Física',
      userpj: 'Pessoa Jurídica',
      completeaddress: 'Endereço completo',
      id: 'CPF',
      phone: 'Número de telefone',
      birth: 'Data de nascimento',
      code: 'Código de amigo (opcional)',
      check: 'Li e concordo com os',
      terms: 'Termos de uso e privacidade',
      goback: 'Voltar',
      submit: 'Continuar',
      submitpj: 'Li e aceito as condições',
      errors: {
        name: 'Por favor preencha seu nome!',
        email: 'Por favor insira seu email!',
        occupation: 'Por favor preencha com sua profissão!',
        wage: 'Por favor preencha com sua renda mensal!',
        cpf: 'Por favor preencha com seu cpf!',
        phone: 'Por favor preencha com seu número de telefone',
        birth: 'Por favor preencha com sua data de nascimento!',
        document: 'Por favor faça upload do documento de identificação!',
        selfie: 'Por favor faça upload da selfie com o documento de identificação!',
        minor: 'Você precisa ser maior de 18 anos!',
        check: 'Por favor concorde com os termos de uso e privacidade!',
        validId: 'Por favor insira um cpf válido!',
        validPhone: 'Por favor insira um número válido',
        address: 'Por favor insira um endereço válido!',
        validCnpj: 'Por favor insira uma cnpj válida.',
        validName: 'Por favor insira um nome!',
        validTerms: 'É necessário concordar com todos os termos para finalizar o processo de cadastro.',
        social: 'Por favor faça upload do contrato social!',
        foundationdate: 'Por favor informe a data de fundação',
        api: {
          title: 'Falha ao registrar',
          email: 'Email já existe',
          cpf: 'Cpf já existe',
          phone: 'Número de telefone já existe',
          cnpj: 'CNPJ já existe',
        },
      },
      emailPage: {
        title: 'Código de segurança',
        body: 'Por favor, insira o código enviado em seu e-mail:',
        send: 'Reenviar código?',
        click: 'Clique aqui!',
        goback: 'Voltar',
        submit: 'Continuar',
        box: 'Por favor preencha todos os campos!',
        tokenErr: 'Falha ao validar código, por favor insira o código enviado em seu email!',
      },
      passwordPage: {
        title: 'Definir senha',
        body: 'Digite a sua senha e comece a invest',
        pass: 'Senha',
        rePass: 'Confirmar senha',
        validationOne: 'Mínimo de 8 caracteres',
        validationTwo: 'Obrigatório ter pelo menos um número',
        validationThree: 'Mínimo uma letra maiuscúla',
        validationFour: 'Um caracter especial: !@#$%^&*(),.?":{}|<>]',
        validationEquals: 'Ambas as senhas são iguais',
        goback: 'Voltar',
        submit: 'Finalizar',
        errors: {
          pass: 'Por favor informe sua senha!',
          confirm: 'Por favor confirme sua senha!',
          val: 'As senhas não conferem!',
          attend: 'Por favor atenda a todos os requisitos para definir sua senha!',
        },
      },
    },
    resetPasswordPage: {
      goback: 'Voltar',
      continue: 'Continuar',
      firstStep: {
        title: 'Redefinição de senha',
        body: 'Insira seu e-mail para redefinirmos a sua senha',
      },
      secondStep: {
        title: 'Código de segurança',
      },
      errors: {
        err: 'Erro',
        email: 'Email não encontrado, insira um email válido',
        token: 'Falha ao validar código, por favor insira o código enviado em seu email',
      },
    },
    titles: {
      dashboard: 'Painel',
      investment: 'Tokens',
      wallet: 'Carteira',
      profile: 'Perfil',
      profilepj: 'Perfil',
      rewards: 'Recompensas',
      security: 'Segurança',
      useTerms: 'Termos de Uso',
      inviteAFriend: 'Indicar um amigo',
      edit: 'Perfil',
      publictokens: 'Tokens',
      walletItems: {
        wallet: 'Minha carteira',
        deposit: 'Meus depósitos',
        withdraw: 'Meus saques',
        invest: 'Meus investimentos',
      },
      logout: 'Sair',
    },
    menuLabel: {
      dashboard: 'Painel',
      investment: 'Investimentos',
      wallet: 'Carteira',
      profile: 'Perfil',
      account: 'Trocar de conta',
      rewards: 'Recompensas',
      security: 'Segurança',
      useTerms: 'Termos de Uso',
      inviteAFriend: 'Indicar um amigo',
    },
    investment: {
      balance: 'Saldo atual:',
      tokenFilter: 'Todos os tokens',
      bestSellers: 'Mais vendidos',
      biggestYield: 'Rendimento',
      biggestValue: 'Maior valor',
      smallestValue: 'Menor valor',
      loadmore: 'Carregar mais',
      buyTokenPage: {
        investTitle: 'Investir',
        nameField: 'Nome do token: ',
        defaultValField: 'Valor de 1 token: ',
        selectField: 'Selecione a quantidade',
        valField: 'Valor a investir',
        submit: 'Investir',
        valReceive: 'Valor a receber',
        dateReceive: 'Receba em ',
        about: 'Sobre o token',
        total: 'Valor total',
        howItWorks: 'Como funciona?',
        alert: 'Caso a captação não seja alcançada durante o prazo estimado, o dinheiro é devolvido automaticamente',
        description: 'Descrição',
        info: 'Informações gerais',
        captation: 'Captação',
        currentCaptation: 'Captação atual',
        remainingTokens: 'Tokens restantes',
        buyers: 'Compradores',
        liquidation: 'Liquidação',
        offeredTokens: 'Tokens ofertados',
        minimumValue: 'Valor mínimo',
        issuer: {
          info: 'Informações do emissor',
          company: 'Empresa',
          foundation: 'Fundação:',
          team: 'Nosso time',
        },
      },
      confirmPurchase: {
        confirmation: 'Confirmação de investimento',
        confirm: 'Confirmar',
        account: 'Conta',
        back: 'Voltar',
      },
      investmentModal: {
        seeToken: 'Ver token',
        complete: 'Investimento concluído',
        overview: 'Resumo',
        investedValue: 'Valor investido',
        toReceive: 'Valor estimado a receber',
        receiveDate: 'Receber em 1x no dia',
        transactionError: 'Não foi possível concluir o investimento',
        attention: 'Atenção',
      },
      noBalanceModal: {
        title: 'Saldo insuficiente',
        body: 'Você ainda não possui o saldo suficiente para finalizar o investimento! deseja realizar um depósito agora?',
        cancel: 'Cancelar',
        Submit: 'Sim, desejo',
      },
    },
    myInvestment: {
      balance: 'Saldo atual:',
      tokenFilter: 'Todos os tokens',
      balanceError: 'Você não tem saldo o suficiente',
      buyTokenPage: {
        valInvest: 'Valor investido',
        quantity: 'Quantidade',
        valReceive: 'Valor a receber',
        dateReceive: 'Receba em ',
        about: 'Sobre o token',
        total: 'Valor total',
      },
    },
    components: {
      select: {
        noOptions: 'Nenhum resultado encontrado',
      },
      footer: {
        title: 'Todos os direitos reservados por ',
        corporate: 'Criptech',
      },
      tableBody: {
        walletdeposit: 'Você não possui nenhum deposito',
        walletwithdrawList: 'Você não possui nenhum saque',
        walletstatement: 'Você não possui nenhum investimento',
      },
      tableButton: {
        walletdeposit: 'Depositar',
        walletwithdrawList: 'Sacar',
        walletstatement: 'Investir',
      },
      tokenCard: {
        value: 'Valor mínimo',
        revenue: 'Rendimento mensal',
        quantity: 'Tokens restantes',
        percentage: 'Comparado ao CDI',
      },
      statementTable: {
        order: 'Pedido',
        name: 'Nome do token',
        date: 'Data da compra',
        quantity: 'Quantidade',
        investVal: 'Valor investido',
        receiveVal: 'Valor a receber',
        received: 'Valor recebido',
        return: 'Estorno',
        status: 'Status',
        action: 'Ações',
        filter: {
          filter: 'Filtros',
          order: 'Pedido',
          name: 'Nome do token',
          date: 'Data inicial da compra',
          fdate: 'Data final da compra',
          quant: 'Quantidade',
          invest: 'Valor investido',
          toReceive: 'Valor a receber',
          received: 'Valor recebido',
          return: 'Estorno',
          selectPH: 'Status',
          selectOptions: {
            cancel: 'Cancelado',
            captated: 'Captado',
            liquidated: 'Concluído',
            incapture: 'Em captação',
          },
          err: {
            title: 'Falha ao filtrar',
            body: 'Cheque as informações ou tente novamente mais tarde',
          },
        },
      },
      statusCard: {
        captation: 'Em Captação',
        complete: 'Concluído',
        cancel: 'Cancelado',
        captated: 'Captado',
        ranOut: 'Esgotado',
        accepted: 'Aprovada',
        analysis: 'Em análise',
      },
      depositTable: {
        order: 'Número do depósito',
        val: 'Valor depositado',
        date: 'Data do depósito',
        time: 'Horário',
        form: 'Forma de pagamento',
      },
      withdrawTable: {
        order: 'Número do saque',
        val: 'Valor sacado',
        date: 'Data do saque',
        key: 'Chave enviada',
        form: 'Forma de pagamento',
      },
    },
    statementPage: {
      title: 'Meus investimentos',
      filter: 'Filtrar',
      blockchain: 'Ver na blockchain',
    },
    walletPage: {
      deposit: 'Desejo realizar um depósito',
      withdraw: 'Desejo realizar um saque',
      emphasisToken: 'Token em destaque',
      statement: 'Extrato',
      currentBalance: 'Saldo atual',
      investedTokens: 'Tokens investidos',
      totalAssets: 'Patrimônio total',
      quantity: 'Quantidade',
      myInvestments: 'Meus investimentos',
      lastInvestments: 'Últimos investimentos',
      errorModal: {
        title: 'Atenção',
        inactiveBody: 'Seu perfil está inativo, então você não poderá investir, entre em contato com o suporte',
        inAnalysisBody: 'Seu perfil está em análise, em breve você poderá investir',
        button: 'Voltar',
      },
    },
    profilePage: {
      wallet: 'Carteira',
      data: 'Meus dados',
      address: 'Endereço',
      walletPlaceholder: 'Informe o endereço da carteira',
      submit: 'Salvar',
      name: 'Nome completo',
      email: 'E-mail',
      phone: 'Telefone',
      occupation: 'Profissão',
      income: 'Renda mensal',
      cpf: 'CPF',
      birth: 'Data de nascimento',
      editSubmit: 'Editar',
      userNotAllowed:
        'Suas informações estão sob análise da nossa equipe, enquanto finalizamos, algumas funções vão estar bloqueadas.',
    },
    tokenCard: {
      minimumValue: 'Valor mínimo',
      monthlyRevenue: 'Rendimento mensal',
    },
    purchaseCard: {
      purchaseOrder: 'Pedido',
      purchaseQuantity: 'Quantidade',
      purchaseInvestedAmount: 'Valor investido',
      purchaseAmountReceivable: 'Valor a receber',
      purchaseChargebackAmount: 'Estorno',
      purchaseReceivedAmount: 'Valor recebido',
      purchaseDate: 'Data da compra',
      purchaseReceivable: 'Recebível',
    },
    depositPage: {
      numberHolder: 'Número do depósito',
      valHolder: 'Valor depositado',
      dateHolder: 'Data inicial do deposito',
      fDateHolder: 'Data final do depósito',
      cleanBtn: 'Limpar filtros',
      title: 'Meus depósitos',
      filter: 'Filtrar',
      pixMsg: 'Invista em criptoativos!',
      blocked: 'Em breve o depósito estará disponível em nossa plataforma!',
      blockedAlt: 'Depósitos bloqueados',
      firstStep: {
        title: 'Adicionar saldo',
        body: 'Quanto você deseja depositar?',
        valError: 'Por favor insira um número diferente de 0!',
        submit: 'Continuar',
      },
      secondStep: {
        body: ' Escaneie o QR Code para realizar o depósito',
        or: 'ou',
        copy: 'Pix Copia e cola',
        copied: 'Pix copiado!',
      },
      errorModal: {
        title: 'Atenção',
        body: 'Não foi possível concluir o depósito.',
        button: 'Voltar',
        inactiveBody: 'Seu perfil está inativo, então você não poderá depositar agora, entre em contato com o suporte.',
        inAnalysisBody: 'Seu perfil está em análise, em breve você poderá depositar.',
      },
    },
    withdrawPage: {
      firstStep: {
        title: 'Sacar',
        body: 'Por favor, insira o código enviado em seu e-mail:',
        submit: 'Continuar',
        resend: 'Email enviado com sucesso!',
        errorTitle: 'Atenção',
        error: 'Não foi possível validar o código, por favor informe o código enviado em seu email!',
      },
      secondStep: {
        title: 'Sacar',
        val: 'Valor do saque',
        warning: 'Para sacar é necessário que seu CPF/CNPJ seja uma chave PIX.',
        max: 'Máximo',
        type: 'Tipo da chave pix',
        key: 'Chave pix',
        placeholder: 'Selecione',
        submit: 'Sacar',
        typeError: 'Por favor selecione um tipo de chave',
        valError: 'Por favor digite um valor a ser sacado',
        keyError: 'Por favor insira uma chave pix',
        emailError: 'Falha ao validar email, por favor insira um email válido',
        cpfError: 'Falha ao validar cpf, por favor insira um cpf válido',
        phoneError: 'Falha ao validar o número de telefone, por favor insira um número válido',
        error: 'Não foi possível concluir o saque!',
      },
      finalStep: {
        title: 'Saque',
        body: 'Saque solicitado com sucesso!',
        goback: 'Voltar',
      },
    },
    withdrawList: {
      order: 'Número do saque',
      date: 'Data inicial do saque',
      pix: 'Chave pix',
      fdate: 'Data final do saque',
      val: 'Valor sacado',
    },
    securityPage: {
      title: 'Alterar senha',
      hint: 'Dicas de segurança',
      email: 'E-mail',
      pass: 'Senha',
      accordionData: {
        hint1: {
          title: 'Proteja sua senha',
          li1: 'Não use a mesma em diferentes plataformas;',
          li2: 'Caso esteja sem ideias para uma senha forte, use um gerenciador de senhas confiável;',
          li3: 'Não deixe a conta logada em computadores públicos;',
          li4: 'Não compartilhe sua senha.',
        },
        hint2: {
          title: 'Recomendações caso tenha um Gmail',
          li1: 'Escolha uma senha forte;',
          li2: 'Adicione opções de recuperação de conta;',
          li3: 'Faça a verificação de segurança do Google;',
          li4: 'Verifique as opções de recuperação de conta.',
        },
        hint3: {
          title: 'Use uma rede confiável',
          li1: 'Evite fazer operações em redes públicas, seja em nossa plataforma ou em transações bancárias, pois seus dados podem ser interceptados;',
          li2: 'Certifique-se de acessar endereços com conexão segura.',
        },
        hint4: {
          title: 'Cuidado com os dados salvos no navegador',
          li1: 'Por mais que possa parecer prático ter suas informações pessoais salvas, outras pessoas podem acessar e se aproveitar disso;',
          li2: 'Utilizar sempre um navegador atualizado.',
        },
      },
      edit: {
        secondStep: {
          title: 'Nova senha',
          body: 'Insira uma nova senha e volte a investir',
        },
      },
    },
  },
};
