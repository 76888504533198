const tr = {
  admin: {
    titles: {
      dashboard: 'Dashboard',
      profile: 'Profile',
      logout: 'Logout',
      investors: 'Investors',
      issuers: 'Issuers',
      legalInvestors: 'Investors PJ',
      proposalList: 'Token proposals',
      tokenProposal: 'Proposta de tokenização',
      investorGroups: 'Investor groups',
    },
    menuLabel: {
      dashboard: 'Dashboard',
      investors: {
        title: 'Investors',
        physicalInvestors: 'Investors',
        legalInvestors: 'Legal users investors',
        investorGroups: 'Investor groups',
      },
      proposalList: 'Token proposals',
      issuers: 'Issuers',
    },
    types: {
      userTypes: {
        IN_ANALYSIS: 'In analysis',
        ACTIVE: 'Active',
      },
      investorTypes: {
        legalperson: 'Legal person',
        physicalperson: 'Physical person',

      },
      issuerTypes: {
        IN_ANALYSIS: 'In analysis',
        APPROVED: 'Approved',
        INCOMPLETE: 'Incomplete',
        ACTIVE: 'Active',
      },
      tokenProposalStatus: {
        inAnalysis: 'In analysis',
        negotiating: 'Negotiating',
        approved: 'Approved',
        rejected: 'Rejected',
        preApproved: 'Pre-approved',
        annulled: 'Annulled',
      },
    },
    pages: {
      login: {
        title: 'Welcome, Admin!',
        body: 'Please inform your email and password to login.',
        email: 'Email',
        password: 'Password',
        submit: 'Login',
        alter: 'Change password',
        noAccount: "Don't have an account?",
        register: 'Register',
        loading: 'Loading...',
        validationErr: {
          email: 'Please inform your email',
          password: 'Please inform your password',
          recaptcha: 'Invalid Recaptcha',
        },
        apiErr: {
          title: 'Failed to login',
          login: 'Please check your login credentials',
        },
      },
      investors: {
        name: 'Name',
        email: 'E-mail',
        type: 'Type',
        status: 'Status',
        title: 'Investors',
        cnpj: 'CNPJ',
        corporateName: 'Corporate name',
        action: 'Action',
        details: 'Details',
        createdAt: 'Created at',
        monthlyIncome: 'Monthly income',
        approve: 'Approve',
        reject: 'Reject',
        documentation: 'Documentation',
        selfieWithDocument: 'Selfie with the document',
        documentPhoto: 'Photo of document',
        cpf: 'CPF',
        socialContract: 'Social contract',
        user: 'User',
        approveSwal: {
          success: "Approved investor ",
          sucessMessage: "The investor was approved successfully, now he can access the investments and make deposits",
        },
        apiErr: {
          title: 'Failed to get investors',
          body: 'Please check your internet connection',
        },

        approveError: {
          title: 'Fails approving investor',
          body: 'Please check your internet connection',
        }
      },
      issuers: {
        name: 'Name',
        email: 'E-mail',
        type: 'Type',
        status: 'Status',
        title: 'Issuers',
        cnpj: 'CNPJ',
        corporateName: 'Corporate name',
        action: 'Action',
        details: 'Details',
        createdAt: 'Created at',
        approve: 'Approve',
        reject: 'Reject',
        documentation: 'Documentation',
        user: 'User',
        socialContract: 'Social contract',
        detailedDescription: 'Detailed description',
        foundationDate: 'Foundation date',
        simpleDescription: 'Simple description',
        shareCapital: 'Share capital',
        approveSwal: {
          success: "Approved issuer ",
          sucessMessage: "The issuer was approved successfully, now he can access the investments and make deposits",
        },
        apiErr: {
          title: 'Failed to get issuers',
          body: 'Please check your internet connection',
        },

        approveError: {
          title: 'Fails approving issuer',
          body: 'Please check your internet connection',
        }
      },
      tokenProposal: {
        title: 'Token proposal',
        image: 'Token image',
        name: 'Token name (Max: 16 characters)',
        invoiceValue: 'Invoice total value',
        fundingDateLimit: 'Receiving date (Withdraw)',
        liquidationDate: 'Payment date (Deposit)',
        discountPercentage: 'Discount rate',
        valueToReceive: 'Value to receive',
        description: 'Token description',
        additionalDoc: 'Fiscal document/Invoice',
        additionalDocDescription: 'Fiscal document description',
        sendProposal: 'Send proposal',
        edit: 'Edit',
        save: 'Save changes',
        alter: 'Send changes',
        cancel: 'Cancel changes',
        addDoc: 'Add another document',
        preview: 'Preview',
        remove: 'Remove document',
        approve: 'Approve',
        counterPropose: 'Counter proposal',
        sendCounterPropose: 'Send counter proposal',
        analysis: 'In analysis',
        counterProposal:
        {
          altered: 'Changed on counter proposal',
          apiErr: {
            title: 'Fail sending counter proposal',
            body: 'Please check your internet connection',
          },
          approveSwal: {
            success: "Counter proposal sended",
            sucessMessage: "The issuer will evaluate the counter proposal",
          },
        },
        upload: {
          apiErr: {
            title: 'Fail uploding image',
            body: 'Please check your internet connection',
          },
        },
        approveProposal: {
          apiErr: {
            title: 'Fail sending proposal',
            body: 'Please check your internet connection',
          },
          approveSwal: {
            success: "Proposal approved",
            sucessMessage: "The generated token is already avaliable",
          },
        },
      },
      investorGroups: {
        title: 'Investor groups',
        name: 'Name',
        description: 'Description',
        details: 'Details',
        action: 'Action',
        investors: 'Investors',
        tokens: 'Tokens',
        userId: 'ID',
        type: 'Type',
        cpfCnpj: 'CPF/CNPJ',
        removeInvestor: 'Remove investor',
        vinculateInvestor: 'Vinculate investor',
        removeToken: 'Remove token',
        vinculateToken: 'Vinculate token',
        vinculateInvestors: 'Vinculate investors',
        vinculateToken: 'Vinculate token',
        vinculateTokens: 'Vinculate tokens',
        cancel: 'Cancel',
        apiErr: {
          title: 'Failed to get group investors',
          body: 'Please check your internet connection',
        },
        detailErr: {
          title: 'Failed to get group investor',
          body: 'Please check your internet connection',
        },
        investorsErr: {
          title: 'Failed to get investors of group investor',
          body: 'Please check your internet connection',
        },
        investorsVinculateErr: {
          title: 'Failed to get investors to vinculate',
          body: 'Please check your internet connection',
        },
        vinculateSwal: {
          success: "Investors vinculated successfully",
          sucessMessage: "Investors have been successfully added to the group",
          fail: "Fail vinculating investors",
          failMessage: "Try again later",
        },
        vinculateSwal: {
          success: "Tokens vinculated successfully",
          sucessMessage: "Tokens have been successfully added to the group",
          fail: "Fail vinculating tokens",
          failMessage: "Try again later",
        },
        removeSwal: {
          success: "Investors removed successfully",
          sucessMessage: "Investors have been removed from group",
          fail: "Fail removing investors",
          failMessage: "Try again later",
        },
        removeTokenSwal: {
          success: "Tokens removed successfully",
          sucessMessage: "Tokens have been removed from group",
          fail: "Fail removing tokens",
          failMessage: "Try again later",
        },
      }
    },
    components: {
      footer: {
        title: 'All rights reserverd by ',
        corporate: 'Criptech',
      },
      proposalsList: {
        name: 'Name',
        invoiceValue: 'Invoice total value',
        valueToReceive: 'Value to receive',
        fundingLimitDate: 'Funding date',
        liquidationDate: 'Liquidation date',
        actions: 'Actions',
        details: 'Details',

        action: {
          edit: 'Edit proposal',
          view: 'View new proposal',
          detail: 'Token details',
        },
      },
    }
  },
};

export default tr;
