import { createContext, useCallback, useContext, useState } from 'react';
import * as adminService from '../../services/Admin/adminService';

export const AdminContext = createContext({});

const AdminProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);
    const [imageBuffer, setImageBuffer] = useState();

    const downloadImage = useCallback(async (id = '') => {
        setLoading(true);
        try {
            const newId = id.replaceAll('/', '%2F');
            const { data } = await adminService.downloadImage(newId);
            setImageBuffer(data);
            return data;
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, []);
    return (
        <AdminContext.Provider
            value={{
                loading,
                downloadImage,
                imageBuffer,
            }}
        >
            {children}
        </AdminContext.Provider>
    );
};

const useAdmin = () => {
    return useContext(AdminContext);
};

export { AdminProvider, useAdmin };
